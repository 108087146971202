import { ScaleSettings } from "./common";
import { viewerSettings } from "./settings";

export class Scale {
  t0: number;
  t1: number;
  p0: number;
  p1: number;
  l0: number;
  l1: number;
  f: number;
  g: number;

  constructor(public readonly settings: ScaleSettings) {
    this.t0 = settings.timeBegin;
    this.t1 = settings.timeEnd;
    this.p0 = settings.displayBegin;
    this.p1 = settings.displayEnd;

    this.l0 = Math.log(this.t0);
    this.l1 = Math.log(this.t1);

    this.f = 1.0 / (this.l1 - this.l0);
    this.g = -this.l0 / (this.l1 - this.l0);
  }

  scale(t: number, dontClamp?: boolean) {
    if (!dontClamp) {
      t = Math.min(Math.max(t, this.t0), this.t1);
    }
    const x = this.f * Math.log(t) + this.g;
    return x * (this.p1 - this.p0) + this.p0;
  }

  scaleInverted(y: number) {
    return Math.exp((y - this.g) / this.f);
  }
}

const scaleSettings: ScaleSettings = {
  timeBegin: 1000,
  timeEnd: viewerSettings.defaultTimeRangeInDays * 24 * 3600 * 1000,
  displayBegin: 0,
  displayEnd: 100, // we use a percentage
};

export const scale = new Scale(scaleSettings);
