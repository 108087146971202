import { scaleSequential, schemeCategory10, color } from "d3";
import logLevel from "loglevel";
import { art } from "./common";
import {
  debugColoration,
  debugMargin,
  debugIgnoreChannelReset,
  debugShift,
} from "./hotkeys";

const log = logLevel.getLogger("screen");

const debugBuffer = false;

const lanesPerChunk = 54;

export function createScreen(
  canvas: HTMLCanvasElement,
  laneCount: number,
  blipCount: number
) {
  function resizeCanvasToDisplaySize(multiplier?: number) {
    multiplier = multiplier || 1;
    multiplier = Math.max(0, multiplier);
    const width = (canvas.clientWidth * multiplier) | 0;
    const height = (canvas.clientHeight * multiplier) | 0;
    if (canvas.width !== width || canvas.height !== height) {
      canvas.width = width;
      canvas.height = height;
      return true;
    }
    return false;
  }

  const gl = canvas.getContext("webgl");

  if (!gl) throw Error("Failed to create webgl context");

  log.info(
    `rendering ${laneCount} lanes in ${Math.floor(
      laneCount / lanesPerChunk
    )} chunks`
  );

  log.info(
    "MAX_FRAGMENT_UNIFORM_VECTORS: " +
      gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS)
  );

  const maxVertexUniformVectors = gl.getParameter(
    gl.MAX_VERTEX_UNIFORM_VECTORS
  );

  log.info("MAX_VERTEX_UNIFORM_VECTORS: " + maxVertexUniformVectors);

  if (maxVertexUniformVectors < 128) {
    throw Error(
      "Blipboard requires a MAX_VERTEX_UNIFORM_VECTORS of at least 128"
    );
  }

  if (!gl.getExtension("OES_element_index_uint"))
    throw Error("This webgl implementation doesn't support uint indexes");

  function createShader(
    gl: WebGLRenderingContext,
    type: number,
    source: string
  ) {
    const shader = gl.createShader(type);
    if (!shader) throw Error("Couldn't create shader");
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
    if (!success) {
      const report = gl.getShaderInfoLog(shader);
      gl.deleteShader(shader);
      throw Error("Failed to create shader: " + report);
    }
    return shader;
  }

  var vertexShaderSource =
    document.getElementById("2d-vertex-shader")?.textContent;
  const fragmentShaderSource =
    document.getElementById("2d-fragment-shader")?.textContent;

  if (!vertexShaderSource || !fragmentShaderSource)
    throw Error("Failed to retrieve shader sources.");

  const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = createShader(
    gl,
    gl.FRAGMENT_SHADER,
    fragmentShaderSource
  );

  function createProgram(
    gl: WebGLRenderingContext,
    vertexShader: WebGLShader,
    fragmentShader: WebGLShader
  ) {
    const program = gl.createProgram();
    if (!program) throw Error("Failed to create program.");
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    const success = gl.getProgramParameter(program, gl.LINK_STATUS);
    if (!success) {
      const report = gl.getProgramInfoLog(program);
      gl.deleteProgram(program);
      throw Error("Failed to create program: " + report);
    }

    return program;
  }

  const program = createProgram(gl, vertexShader, fragmentShader);

  const dataBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, dataBuffer);

  function createAttributeLocation(name: string, size: number, type: number) {
    const positionAttributeLocation = gl!.getAttribLocation(program, name);
    gl!.enableVertexAttribArray(positionAttributeLocation);
    gl!.vertexAttribPointer(positionAttributeLocation, size, type, false, 0, 0);
  }

  createAttributeLocation("a_data", 4, gl.FLOAT);

  const laneRangesLocation = gl.getUniformLocation(program, "u_lane_ranges");
  const laneRangesExtraLocation = gl.getUniformLocation(
    program,
    "u_lane_ranges_extra"
  );

  const laneRanges = new Array(laneCount * 4);
  const laneRangesExtra = new Array(laneCount * 4);

  const debugColorsLocation = gl.getUniformLocation(program, "u_debug_colors");
  const debugColors = new Array(10 * 4);

  const timeBeginLocation = gl.getUniformLocation(program, "u_time_begin");
  const timeEndLocation = gl.getUniformLocation(program, "u_time_end");
  const nowLocation = gl.getUniformLocation(program, "u_now");

  const transformLocation = gl.getUniformLocation(program, "u_transform");
  const valueTransformLocation = gl.getUniformLocation(
    program,
    "u_value_transform"
  );

  const scaleLocation = gl.getUniformLocation(program, "u_scale");
  const marginsLocation = gl.getUniformLocation(program, "u_margins");

  const debugShiftXLocation = gl.getUniformLocation(program, "u_debug_shift_x");
  const debugColorationLocation = gl.getUniformLocation(
    program,
    "u_debug_coloration"
  );

  for (var i = 0; i < 10; ++i) {
    const c = color(schemeCategory10[i % 10])!.rgb();
    debugColors[i * 4] = c.r / 255;
    debugColors[i * 4 + 1] = c.g / 255;
    debugColors[i * 4 + 2] = c.b / 255;
    debugColors[i * 4 + 3] = 1;
  }

  const vertexCount = laneCount * blipCount * 6;

  var dataArrayBuffer = new ArrayBuffer(vertexCount * 4 * 4);

  log.info(`data array size is ${dataArrayBuffer.byteLength >> 10} KB`);

  const dataArray = new Float32Array(dataArrayBuffer);

  let updatePending = false;

  function signToPrime(s: number, p: number) {
    if (s == -1) {
      return 1;
    } else if (s == 1) {
      return p;
    } else {
      throw new Error("Expected 1 or -1");
    }
  }

  let hadLaneExcessWarning = false;

  function checkLane(laneIndex: number) {
    const warn = laneIndex >= laneCount;
    if (warn && !hadLaneExcessWarning) {
      hadLaneExcessWarning = true;
      console.warn(`laneIndex ${laneIndex} exceeds ${laneCount}`);
    }
    return warn;
  }

  function setVertex(
    index: number,
    laneData: number,
    absoluteTime: number,
    value: number,
    h: number,
    v: number
  ) {
    let offset = index * 4;

    if (offset + 3 >= dataArrayBuffer.byteLength)
      throw Error("Vertex array access out of bounds");

    dataArray[offset] = signToPrime(h, 2) * signToPrime(v, 3);
    dataArray[offset + 1] = value;
    dataArray[offset + 2] = absoluteTime;
    dataArray[offset + 3] = laneData;
  }

  function setRectangle(
    laneIndex: number,
    blipIndex: number,
    level: number,
    isVisible: boolean,
    bt: number,
    et: number,
    bv0: number,
    ev0: number,
    bv1: number,
    ev1: number
  ) {
    if (blipIndex < 0) throw Error(`negative blipIndex`);
    if (blipIndex >= blipCount) throw Error(`blipIndex >= blipCount`);
    if (laneIndex >= laneCount)
      throw Error(`laneIndex ${laneIndex} >= laneCount ${laneCount}`);

    //let offset = (laneCount * blipIndex + laneIndex) * 6;
    let offset = (blipIndex + blipCount * laneIndex) * 6;

    const laneIndexInChunk = laneIndex % lanesPerChunk;

    const ld = isVisible ? laneIndexInChunk + 1 + (level & 0xff) / 256.0 : 0;

    setVertex(offset++, ld, bt, bv0, -1, -1);
    setVertex(offset++, ld, bt, bv1, +1, -1);
    setVertex(offset++, ld, et, ev0, -1, +1);

    setVertex(offset++, ld, bt, bv1, +1, -1);
    setVertex(offset++, ld, et, ev1, +1, +1);
    setVertex(offset++, ld, et, ev0, -1, +1);
  }

  gl.bufferData(gl.ARRAY_BUFFER, dataArray, gl.DYNAMIC_DRAW);

  gl.useProgram(program);

  gl.uniform2f(scaleLocation, 1, -1);

  gl.uniform4fv(debugColorsLocation, debugColors);

  const getFirstVertexIndex = (lane: number) => {
    return lane * blipCount * 2 * 3;
  };

  const sliceLaneArray = (array: any[], start: number, end: number) => {
    return array.slice(start * 4, end * 4);
  };

  const renderSection = (start: number, end: number) => {
    gl.uniform4fv(laneRangesLocation, sliceLaneArray(laneRanges, start, end));
    gl.uniform4fv(
      laneRangesExtraLocation,
      sliceLaneArray(laneRangesExtra, start, end)
    );

    gl.drawArrays(
      gl.TRIANGLES,
      getFirstVertexIndex(start),
      getFirstVertexIndex(end) - getFirstVertexIndex(start)
    );
  };

  return {
    dispose: () => {
      gl.deleteProgram(program);
      gl.deleteShader(fragmentShader);
      gl.deleteShader(vertexShader);
    },
    setBlip: (
      laneIndex: number,
      blipIndex: number,
      level: number, // only for debugging
      timeBegin: number,
      timeEnd: number,
      min: number,
      max: number
    ) => {
      if (checkLane(laneIndex)) return;

      setRectangle(
        laneIndex,
        blipIndex,
        level,
        true,
        timeBegin - art,
        timeEnd - art,
        min,
        min,
        max,
        max
      );
    },
    clearBlip: (laneIndex: number, blipIndex: number) => {
      setRectangle(laneIndex, blipIndex, 0, false, 0, 0, 0, 0, 0, 0);
    },
    updatePage: (blipIndex: number) => {
      updatePending = true;
    },
    setViewport: (
      timeBegin: number,
      timeEnd: number,
      blipBreadthMargin: number,
      blipLength: number,
      now: number,
      horizontal: boolean
    ) => {
      const tr = horizontal ? [0, -1, 1, 0] : [1, 0, 0, 1];

      gl.uniform1f(timeBeginLocation, timeBegin);
      gl.uniform1f(timeEndLocation, timeEnd);
      gl.uniform2f(
        marginsLocation,
        blipBreadthMargin,
        debugMargin > 0 ? blipLength : 0
      );
      gl.uniform1f(nowLocation, now - art);
      gl.uniformMatrix2fv(transformLocation, false, tr);
      gl.uniform1f(valueTransformLocation, horizontal ? -1.0 : 1.0);
      gl.uniform1f(debugShiftXLocation, debugShift ? blipLength : 0.0);
      gl.uniform1i(debugColorationLocation, debugColoration);
    },
    setLane: (
      laneIndex: number,
      r: number,
      g: number,
      b: number,
      x0: number,
      x1: number,
      resetTime: number,
      mode: number,
      minValue: number,
      maxValue: number
    ) => {
      if (checkLane(laneIndex)) return;

      if (Number.isNaN(minValue)) throw Error(`minFlow is NaN`);
      if (Number.isNaN(maxValue)) throw Error(`maxFlow is NaN`);

      const cutTime = debugIgnoreChannelReset ? 0 : resetTime - art;

      laneRanges[laneIndex * 4 + 0] = x0;
      laneRanges[laneIndex * 4 + 1] = x1;
      laneRanges[laneIndex * 4 + 2] = cutTime;
      laneRanges[laneIndex * 4 + 3] = r * 65536 + g * 256 + b;

      laneRangesExtra[laneIndex * 4 + 0] = minValue;
      laneRangesExtra[laneIndex * 4 + 1] = maxValue;
      laneRangesExtra[laneIndex * 4 + 2] = 0.0;
      laneRangesExtra[laneIndex * 4 + 3] = mode;
    },
    render: () => {
      resizeCanvasToDisplaySize();

      if (updatePending || debugBuffer) {
        gl.bufferData(gl.ARRAY_BUFFER, dataArray, gl.DYNAMIC_DRAW);
        updatePending = false;
      }

      gl.viewport(0, 0, canvas.width, canvas.height);

      gl.clearColor(0, 0, 0, 0);
      gl.clear(gl.COLOR_BUFFER_BIT);

      for (let l = 0; l < laneCount; l += lanesPerChunk) {
        renderSection(l, Math.min(l + lanesPerChunk, laneCount));
      }
    },
  };
}
