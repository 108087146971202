import * as React from "react";
import logLevel from "loglevel";
import {
  Observer,
  useObserver,
  useLocalStore,
  observer,
  useObservable,
} from "mobx-react-lite";
import {
  Address,
  GetCurtainStatusString,
  Ingestoid,
  GetCurtainShouldBeOpen,
  LaneCell,
  formatAddress,
} from "./common";
import {
  ResizeSensor,
  Button,
  ButtonGroup,
  Tag,
  Card,
  Classes,
} from "@blueprintjs/core";
import { Driver } from "./driver";
import { scale } from "./board";
import { createScreen } from "./screen";
import classnames from "classnames";
import { autorun } from "mobx";
import { useFetch as useFetcher } from "./fetch";
import { Channel } from "./channels";
import dayjs, { Dayjs, OpUnitType } from "dayjs";
import { Connection } from "./connections/connection";
import { areDevFeaturesEnabled } from ".";
import {
  debugLabels,
  debugSettings,
  deleteChannelCommand,
  HotCommand,
  redeemAlarmsCommand,
  toggleChannelArmingCommand,
} from "./hotkeys";
import { DetailsDisplay } from "./details-display";
import { useState } from "react";
import { getFittingTimeUnit, useAnimationFrame } from "./util";
import { useEffect } from "react";
import { OperationRunnerDisplay, useOperationRunner } from "./operation-runner";
import { viewerSettings } from "./settings";
import { getLabelColorForChannelColor } from "./color";
import {
  BackgroundScreen,
  createBackgroundScreen,
} from "./temporal-background/renderer";
import { getGeoLocationInfo } from "./geolocation";

const log = logLevel.getLogger("chrome");

const subdueColors = false;
const subdueTotalOpacity = 1;

const overlayFadeDelay = 10 * 1000;

const useFullAddressBlips = false;
const debugInitLanes = false;

interface AppProps {
  connection: Connection;
  interactive: boolean;
  hideCurtain?: boolean;
  teaser?: string | null;
}

const dateTimeFormat = Intl.DateTimeFormat("de", {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",

  hour: "2-digit",
  minute: "2-digit",
});

const simpleNumberFormat = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 1,
} as any);

const numberFormat = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
} as any);

const numberFormat4 = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
} as any);

function formatNumber(n?: number) {
  if (typeof n === "undefined") return "n/a";
  return numberFormat.format(n);
}

interface DisplayDimensions {
  horizontal: boolean;
  breadth: number;
  length: number;

  screenWidth: number;
  screenHeight: number;
}

class TimeLine extends React.Component<{
  relativeTime: number;
  y: number;
  x: number;
  isRightRatherThanLeft: boolean;
  isTopRatherThanBottom: boolean;
  displayDimensions: DisplayDimensions;
  channel?: Channel;
  laneCell?: LaneCell;
  driver: Driver;
}> {
  getFormattedTime() {
    const absoluteTime = this.props.driver.visualNow - this.props.relativeTime;

    try {
      return dateTimeFormat.format(absoluteTime);
    } catch (ex) {
      log.error(
        `Could not format time, relative time was ${this.props.relativeTime}`
      );
      return "n/a";
    }
  }

  render() {
    const { horizontal, breadth, length, screenHeight, screenWidth } =
      this.props.displayDimensions;

    const channel = this.props.channel;
    const ingestion = channel?.keyIngestoid;
    const color = channel?.color.hex();

    const { x0, x1 } = channel ?? { x0: 0, x1: 0 };

    const b = channel ? x1 - x0 : 10;
    const margin: React.CSSProperties = {
      left: !horizontal ? b * screenWidth : 0,
      right: !horizontal ? b * screenWidth : 0,
      top: horizontal ? b * screenHeight : 0,
      bottom: horizontal ? b * screenHeight : 0,
      position: "absolute",
    };

    const centerStyle = {
      left: channel && !horizontal ? x0 * screenWidth : this.props.x,
      top: channel && horizontal ? x0 * screenHeight : this.props.y,
      width: channel && !horizontal ? (x1 - x0) * screenWidth : 0,
      height: channel && horizontal ? (x1 - x0) * screenHeight : 0,
    };

    const cell = this.props.laneCell;

    const styles: React.CSSProperties = {
      left: horizontal ? this.props.x : undefined,
      top: horizontal ? undefined : this.props.y,
    };

    return (
      <div
        className={classnames({
          timeline: true,
          "is-right-rather-than-left": this.props.isRightRatherThanLeft,
          "is-top-rather-than-bottom": this.props.isTopRatherThanBottom,
        })}
      >
        <div className="timeline-border" style={styles} />
        <div className="timeline-center" style={centerStyle}>
          <div className="timeline-small-side" style={margin}>
            <div className="timeline-time-indicator">
              {this.getFormattedTime()}
            </div>
            {cell && channel && (
              <TimelineCellInfoPart cell={cell} channel={channel} />
            )}
          </div>
          {ingestion && (
            <div className="timeline-channel-side" style={margin}>
              {ingestion.parsingError && (
                <div className="timeline-parsing-error-indicator">
                  {ingestion.parsingError}
                </div>
              )}
              <div className="timeline-name-indicator">
                {ingestion.level && (
                  <>
                    <span
                      className="timeline-level-indicator"
                      style={{ color }}
                    >
                      {ingestion.level}
                    </span>
                    <span className="timeline-name-gap"></span>
                  </>
                )}
                <span className="timeline-label-indicator">
                  {ingestion.label}
                </span>
              </div>
              <div className="timeline-msg_template-indicator">
                {ingestion.msg_template}
              </div>
              {debugSettings.timelineDebugInfo && (
                <TimelineDebugInfoPart cell={cell} channel={channel} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function TimelineCellInfoPart(props: { cell: LaneCell; channel: Channel }) {
  const { cell, channel } = props;
  const ingestion = channel.keyIngestoid;
  const { blipSeconds, cellSeconds } = cell;
  const { mode, count, begin, end } = cell.pageValue;

  if (!ingestion) return <></>;

  if (mode === "value") {
    return (
      <div>
        <span>{numberFormat4.format(end)}</span> <span>{ingestion.unit}</span>
      </div>
    );
  } else if (mode === "blip") {
    var throughput = getFittingTimeUnit(
      false,
      (s) => (count / cellSeconds) * s
    );
    var span = getFittingTimeUnit(true, (s) => cellSeconds / s);
    return (
      <>
        <div>
          <span>{simpleNumberFormat.format(count)}</span>{" "}
          <span>
            {ingestion.unit} within {numberFormat.format(span.value)}{" "}
            {span.unit}s, {numberFormat.format(throughput.value)}{" "}
            {ingestion.unit} per {throughput.unit}
          </span>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

function TimelineDebugInfoPart(props: { cell?: LaneCell; channel?: Channel }) {
  const { cell, channel } = props;

  if (!channel) return <div></div>;

  const channelModeFromKey = channel.keyIngestoid?.mode ?? "blip";

  const channelModeIncongruent =
    channel.keyIngestoid && channelModeFromKey !== channel.mode;

  return (
    <div>
      <div>
        <span>
          channel {channel.index} (#{channel.layoutOrder}), mode:{" "}
        </span>
        <span>{channel!.mode}</span>
        {channelModeIncongruent && (
          <span className="text-warning">
            {" "}
            (inconguently '{channelModeFromKey}' in key)
          </span>
        )}
        <span>, min: </span>
        <span>{formatNumber(channel.minValue)}</span>
        <span>, max: </span>
        <span>{formatNumber(channel.maxValue)}</span>
      </div>
      {cell && (
        <>
          <div>
            <span>address, source: </span>
            <span>{cell.pageValue.page.source}</span>
            <span>, expo: </span>
            <span>{numberFormat.format(cell.pageValue.address.expo)}</span>
            <span>, formatted: </span>
            <span>{formatAddress(cell.pageValue.address)}</span>
            <span>, spans: </span>
            <span>{numberFormat.format(cell.cellSeconds)}s</span>
          </div>
          <div>
            <span>cell, mode: </span>
            <span>
              {cell.pageValue.mode}{" "}
              {cell.pageValue.page.getFlags(channel.index)}
            </span>
            <span>, min: </span>
            <span>{formatNumber(cell.pageValue.begin)}</span>
            <span>, max: </span>
            <span>{formatNumber(cell.pageValue.end)}</span>
          </div>
        </>
      )}
      <div>
        <pre>{channel.keyIngestoid?.json}</pre>
      </div>
    </div>
  );
}

function LaneLine(props: {
  channel: Channel;
  laneCell?: LaneCell;
  displayDimensions: DisplayDimensions;
}) {
  const { horizontal, breadth, length } = props.displayDimensions;

  const driver = React.useContext(DriverContext);
  const cell = props.laneCell;
  const getL = (time: number) => {
    const top = (driver.getPercentageFromTime(time) / 100) * length;
    return top;
  };
  const markerHeight = 2;
  const { x0, x1 } = props.channel;
  const b = x1 - x0;

  const screenX0 = x0 * breadth;
  const screenB = b * breadth;

  const styles: React.CSSProperties = {
    left: horizontal ? undefined : screenX0,
    top: horizontal ? screenX0 : undefined,
    width: horizontal ? undefined : screenB,
    height: horizontal ? screenB : undefined,
  };

  return (
    <div className="laneline" style={styles}>
      {areDevFeaturesEnabled && (
        <>
          <div
            style={{
              top: getL(props.channel.latestTime),
              background: "red",
              height: markerHeight,
            }}
          ></div>
          <div
            style={{
              top: getL(props.channel.resetTime),
              background: "orange",
              height: markerHeight,
            }}
          ></div>
          {cell && (
            <>
              <div
                style={{
                  top: getL(cell.pageValue.end) - markerHeight,
                  background: "green",
                  height: markerHeight,
                }}
              ></div>
              <div
                style={{
                  top: getL(cell.pageValue.begin),
                  background: "blue",
                  height: markerHeight,
                }}
              ></div>
            </>
          )}
        </>
      )}
    </div>
  );
}

function LabelDisplay(props: Ingestoid) {
  return (
    <div className="drawer-content-label">
      <div>{props.label}</div>
    </div>
  );
}

function MessageDisplay(props: { channel: Channel }) {
  const c = props.channel;
  const message =
    c.bodyIngestoid?.message ??
    c.bodyIngestoid?.msg_template ??
    c.keyIngestoid?.msg_template;

  return (
    <div
      className="drawer-content-message"
      style={{
        backgroundColor: "white",
      }}
    >
      <div>{message ? message : <em>no message</em>}</div>
    </div>
  );
}

function DrawerContentFriendly(props: { channel: Channel }) {
  const channel = props.channel;
  const ingestoid = channel.bodyIngestoid ?? channel.keyIngestoid;

  if (!ingestoid) return <div />;

  return (
    <>
      {ingestoid.label && <LabelDisplay {...ingestoid} />}
      {<MessageDisplay channel={props.channel} />}
      {ingestoid.details && <DetailsDisplay {...ingestoid} />}
    </>
  );
}

function DrawerContentRaw(props: { channel: Channel }) {
  const ingestoid = props.channel.bodyIngestoid;

  if (!ingestoid) return <div />;

  return <div className="drawer-content-raw">{ingestoid?.json}</div>;
}

const stopPropagation = (e: React.BaseSyntheticEvent<any>) =>
  e.stopPropagation();

const preventDefault = (e: React.BaseSyntheticEvent<any>) => e.preventDefault();

const LaneDrawer = observer(
  (props: { channel: Channel; isRightRatherThanLeft: boolean }) => {
    const driver = React.useContext(DriverContext);

    const fetcher = useFetcher(
      () => driver.loadBody(props.channel),
      () => {}
    );

    const state = useObservable({
      showRaw: false,
    });

    React.useEffect(() => {
      fetcher.update();
    }, []);

    const DrawerContent = state.showRaw
      ? DrawerContentRaw
      : DrawerContentFriendly;

    const channel = props.channel;

    const ingestoid = channel.bodyIngestoid ?? channel.keyIngestoid;

    return (
      <div
        onClick={stopPropagation}
        onMouseUp={stopPropagation}
        onTouchEnd={stopPropagation}
        onMouseDown={stopPropagation}
        onTouchStart={stopPropagation}
        onMouseMove={stopPropagation}
        onTouchMove={stopPropagation}
        className={classnames({
          drawer: true,
          "drawer-is-right": props.isRightRatherThanLeft,
        })}
      >
        <div className="drawer-header">
          <Tag
            large={true}
            style={{
              opacity: ingestoid?.level ? undefined : 0,
              color: "white",
              backgroundColor: channel.color.darken(5).hex(),
              fontWeight: "bold",
            }}
          >
            {ingestoid?.level}
          </Tag>
          <ButtonGroup minimal={true}>
            <Button
              icon="eye-on"
              active={state.showRaw}
              onClick={() => (state.showRaw = !state.showRaw)}
            />
            <Button
              icon="refresh"
              onClick={() => {
                fetcher.update();
              }}
              loading={fetcher.loading}
            />
          </ButtonGroup>
        </div>

        <div className="drawer-content">
          <DrawerContent channel={channel} />
        </div>

        {/* <div className="drawer-footer mt-auto text-right pr-2">
          <span className="text-muted">
            {channel.bodyIngestoidDate &&
              dateTimeFormat.format(channel.bodyIngestoidDate.getTime())}
            &nbsp;
          </span>
        </div> */}
      </div>
    );
  }
);

const DriverContext = React.createContext(undefined as any as Driver);

const TemporalBackground = () => {
  const canvas = React.useRef<HTMLCanvasElement>(null);
  const screenRef = React.useRef<BackgroundScreen>();

  const driver = React.useContext(DriverContext);

  useEffect(() => {
    screenRef.current = createBackgroundScreen(canvas.current!, viewerSettings);
  }, []);

  const updateBackground = () => {
    const geoLocationInfo = getGeoLocationInfo();

    screenRef.current!.render(
      driver.visualNow,
      scale.t0,
      scale.t1,
      viewerSettings.orientation,
      0,
      geoLocationInfo
    );
  };

  useAnimationFrame(() => {
    updateBackground();
  });

  return (
    <canvas
      ref={canvas}
      className="time-background position-absolute w-100 h-100"
    />
  );
};

function ScaleLine(props: {
  width: number;
  thickness: number;
  y: number;
  c: string;
}) {
  return (
    <line
      stroke={props.c}
      strokeWidth={props.thickness}
      x1="0"
      x2={props.width}
      y1={props.y}
      y2={props.y}
    />
  );
}

function ScreenUnderlay(props: { width: number; height: number }) {
  const driver = React.useContext(DriverContext);

  const [time, setTime] = useState(driver.now);

  useAnimationFrame((frame) => {
    setTime(driver.now);
  });

  const ss = scale.settings;
  const now = dayjs(time);

  const f = props.height / (ss.displayEnd - ss.displayBegin);

  function getY(t: Dayjs) {
    return scale.scale(time - t.unix() * 1000) * f;
  }

  function getScanLines(unit: OpUnitType, maxLines?: number) {
    let current = now.startOf(unit);
    const lines: React.ReactNodeArray = [];
    let isFirst = true;
    while (true) {
      const currentY = getY(current);
      lines.push(
        <ScaleLine
          width={props.width}
          thickness={2}
          y={currentY}
          c={isFirst ? "#333" : "#282820"}
        />
      );

      if (maxLines && --maxLines === 0) return lines;

      const next = current.subtract(1, unit);
      const nextY = getY(next);
      if (nextY - currentY < 10) return lines;

      current = next;
      isFirst = false;
    }
  }

  return (
    <svg
      className="underlay"
      preserveAspectRatio="none"
      viewBox={`0 0 ${props.width} ${props.height}`}
    >
      {/* {getScanLines("minute")} */}
      {/* {getScanLines("hour", 1)} */}
      {/* {getScanLines("day", 7)} */}
    </svg>
  );
}

function useCommand(command: HotCommand, handler: () => any) {
  React.useEffect(() => {
    command.setHandler(handler);
    return () => command.setHandler(undefined);
  });
}

function ScreenOverlay(props: { displayDimensions: DisplayDimensions }) {
  const driver = React.useContext(DriverContext);

  const overlayRef = React.useRef<HTMLDivElement>(null);

  const { horizontal, breadth, length, screenWidth, screenHeight } =
    props.displayDimensions;

  const model = useLocalStore(() => ({
    screenX: 0,
    screenY: 0,
    currentTimeout: 0,
    isRightRatherThanLeft: false,
    isTopRatherThanBottom: false,
    hoveredChannel: undefined as Channel | undefined,
    laneCell: undefined as LaneCell | undefined,
    relativeTime: 0,
    active: false,
    clamped: false,
  }));

  const breadthCoord = horizontal ? model.screenY : model.screenX;
  const lengthCoord = horizontal ? length - model.screenX : model.screenY;

  const operationRunner = useOperationRunner();

  async function handleBoardRedeemingCommand() {
    operationRunner.run({
      busyMessage: "redeeming",
      successMessage: "redeemed",
      failureMessage: "request failed",
      operation: () => driver.redeemAlarms(),
    });
  }

  async function handleDeleteChannelCommand() {
    const hc = model.hoveredChannel;
    if (!hc) return;
    operationRunner.run({
      busyMessage: "deleting",
      successMessage: "deleted",
      failureMessage: "request failed",
      operation: () => driver.deleteChannel(hc.index),
    });
  }

  async function handleChannelAlarmCommand() {
    const hc = model.hoveredChannel;

    if (
      !hc ||
      hc.alarmClass === "alarmed-none" ||
      hc.alarmClass === "alarmed-newly"
    )
      return;

    const isArmed = hc.alarmClass === "alarmed-rearmed";

    operationRunner.run({
      busyMessage: isArmed ? "clearing" : "rearming",
      successMessage: isArmed ? "cleared" : "rearmed",
      failureMessage: "request failed",
      operation: () => driver.setChannelAlarm(hc.index, !isArmed),
    });
  }

  useCommand(toggleChannelArmingCommand, handleChannelAlarmCommand);
  useCommand(redeemAlarmsCommand, handleBoardRedeemingCommand);
  useCommand(deleteChannelCommand, handleDeleteChannelCommand);

  function resetTimer() {
    if (model.currentTimeout) window.clearTimeout(model.currentTimeout);
    const thisTimeout = (model.currentTimeout = window.setTimeout(() => {
      if (model.clamped) return;
      if (thisTimeout !== model.currentTimeout) return;

      model.active = false;
    }, overlayFadeDelay));
  }

  function onHover(coord: { clientX: number; clientY: number }) {
    if (model.clamped) return;

    resetTimer();

    model.screenX = coord.clientX;
    model.screenY = coord.clientY;
    const hc = (model.hoveredChannel =
      driver.channels!.getChannelFromBreadthOffset(breadthCoord, breadth));
    model.relativeTime = scale.scaleInverted(lengthCoord / length);
    model.laneCell = hc
      ? driver.getLaneCellFromTime(hc.index, model.relativeTime)
      : undefined;
    model.isRightRatherThanLeft = model.screenX < screenWidth / 2;
    model.isTopRatherThanBottom = model.screenY < screenHeight * 0.9;
    model.active = true;
  }

  function onClick() {
    if (!model.hoveredChannel || !model.active) return;

    if (model.clamped) {
      model.clamped = false;
      driver.channels!.setDebugChannel(undefined);
    } else {
      model.clamped = true;
      driver.channels!.setDebugChannel(model.hoveredChannel?.index);
      resetTimer();
    }
  }

  return useObserver(() => {
    const hc = model.hoveredChannel;

    return (
      <div
        className={classnames({
          overlay: true,
          "overlay-on": model.active,
          "overlay-clamped": model.clamped,
        })}
        onTouchStart={(e) => {
          onHover(e.touches[0]);
        }}
        onTouchEnd={(e) => {
          // prevent mobile context menues
          e.preventDefault();
          onClick();
        }}
        onMouseDown={onHover}
        onMouseUp={(e) => {
          onHover(e);
          onClick();
        }}
        onTouchMove={(e) => onHover(e.touches[0])}
        onMouseMove={(e) => onHover(e)}
        // onPointerDown={(e) => {
        //   console.info(`onPointerDown`);
        //   //onHover(e);
        // }}
        // // onTouchMove={(e) => onHover(e.touches[0])}
        // onPointerUp={(e) => {
        //   console.info(`onPointerUp`);
        //   //onClick();
        // }}
        // onMouseMove={onHover}
        ref={overlayRef}
      >
        {/* <LaneChart lanes={driver.channels!.getVisibleChannels()} /> */}
        <TimeLine
          driver={driver}
          y={model.screenY}
          x={model.screenX}
          isRightRatherThanLeft={model.isRightRatherThanLeft}
          isTopRatherThanBottom={model.isTopRatherThanBottom}
          displayDimensions={props.displayDimensions}
          channel={hc}
          laneCell={model.laneCell}
          relativeTime={model.relativeTime}
        />
        {/* place for temp debug info */}
        <div></div>
        {hc && (
          <LaneLine
            channel={hc}
            laneCell={model.laneCell}
            displayDimensions={props.displayDimensions}
          />
        )}
        {hc && model.clamped && (
          <LaneDrawer
            key={hc.index}
            channel={hc}
            isRightRatherThanLeft={model.isRightRatherThanLeft}
          />
        )}

        {/* <AddressRows
        addresses={driver.displayedPages.map(p => p.address)}
        Content={AddressFrame}
      /> */}
        <OperationRunnerDisplay {...operationRunner.state} />
      </div>
    );
  });
}

const Curtain = observer(() => {
  const driver = React.useContext(DriverContext);

  const state = useObservable({
    open: true,
    status: "",
  });

  React.useEffect(
    () =>
      autorun(() => {
        state.status = GetCurtainStatusString(driver.status);
        state.open = GetCurtainShouldBeOpen(driver.status) ?? state.open;
      }),
    []
  );

  const className = classnames(
    "curtain d-flex flex-column h-100 w-100 position-absolute justify-content-center align-items-center",
    { open: state.open }
  );

  return (
    <div className={className}>
      {/* <div className="curtain-caption mb-4">
        <h1 className="caption-1">Blipboard</h1>
      </div> */}
      <div className="h1 curtain-status mb-5">
        <div>{state.status}</div>
        <small className="text-muted">
          <wbr />
          {driver.internalStatus}
        </small>
      </div>
    </div>
  );
});

export function App(props: AppProps) {
  const [driver] = React.useState(() => new Driver(props.connection));

  React.useEffect(() => {
    driver.start();
  }, []);

  return (
    <DriverContext.Provider value={driver}>
      <Observer>
        {() => (
          <>
            {driver.boardSettings && (
              <Chrome interactive={props.interactive} teaser={props.teaser} />
            )}
            {props.hideCurtain || <Curtain />}
          </>
        )}
      </Observer>
    </DriverContext.Provider>
  );
}

function useScreen() {
  const screenRef = React.useRef<HTMLCanvasElement>(null);

  const driver = React.useContext(DriverContext);

  const settings = driver.boardSettings!;

  let updateScreen = () => {};
  let updateLanes = () => {};

  const requestRef = React.useRef<number>();

  const animate = () => {
    updateScreen();
    requestRef.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    animate();
    return () => cancelAnimationFrame(requestRef.current!);
  }, []);

  React.useEffect(() => {
    const screen = createScreen(
      screenRef.current!,
      driver.nonFixedChannels,
      settings.pagesPerShift * settings.maxShift
    );

    function getBlipIndex(address: Address) {
      return (
        address.expo * settings.pagesPerShift +
        ((address.manti + settings.pagesPerShift) % settings.pagesPerShift)
      );
    }

    updateScreen = () => {
      const ss = scale.settings;
      const now = driver.now;
      const blipSize =
        scale.scale((settings.pagesPerShift >> 1) + 1, true) -
        scale.scale(settings.pagesPerShift >> 1, true);
      driver.visualNow = now;
      screen.setViewport(
        ss.timeBegin,
        ss.timeEnd,
        0.001,
        blipSize / 100,
        now,
        viewerSettings.orientation == "r2l"
      );
      screen.render();
    };

    updateLanes = () => {
      if (debugInitLanes) {
        for (let i = 0; i < driver.nonFixedChannels; ++i) {
          screen.setLane(i, 255, 255, 255, -1, 1, 0, 0, 0, 1);
        }
      }
      for (let i = 0; i < driver.nonFixedChannels; ++i) {
        const channel = driver.channels!.getChannel(i);

        if (channel.hasContent) {
          let [r, g, b] = channel.color.rgb();
          screen.setLane(
            i,
            r,
            g,
            b,
            channel.x0 * 2 - 1,
            channel.x1 * 2 - 1,
            channel.resetTime,
            channel.mode === "value" ? 1 : 0,
            channel.minValue ?? 0,
            channel.maxValue ?? 1
          );
        } else {
          screen.setLane(i, 0, 0, 0, 0, 0, 0, 0, 0, 1);
        }
      }
    };

    const disposeSubscription = driver.subscribe({
      init: () => {},
      reset: () => {},
      addPage: (address, page) => {
        if (page) {
          const j = getBlipIndex(address);
          for (let i = 0; i < driver.nonFixedChannels; ++i) {
            const c = page.getCount(i);
            if (c > 0) {
              const mode = page.getMode(i);
              const isValueMode = mode === "value";
              if (isValueMode || useFullAddressBlips) {
                const addressBegin =
                  driver.timeConversion!.getUnixTimeFromAddress(address);
                const addressEnd =
                  driver.timeConversion!.getUnixTimeFromAddress(address, true);
                screen.setBlip(
                  i,
                  j,
                  address.expo,
                  addressBegin,
                  addressEnd,
                  isValueMode ? page.getBegin(i) : 0,
                  isValueMode ? page.getEnd(i) : 1
                );
              } else {
                screen.setBlip(
                  i,
                  j,
                  address.expo,
                  page.getBegin(i),
                  page.getEnd(i),
                  0,
                  1
                );
              }
            } else {
              screen.clearBlip(i, j);
            }
          }
          screen.updatePage(j);
        }
        updateLanes();
      },
      removePage: (address) => {
        const j = getBlipIndex(address);
        for (let i = 0; i < driver.nonFixedChannels; ++i) {
          screen.clearBlip(i, j);
        }
        screen.updatePage(j);
      },
    });

    return () => {
      disposeSubscription();
      screen.dispose();
    };
  }, []);

  return screenRef;
}

const ChannelLabel = observer(function (props: {
  channel: Channel;
  breadth: number;
  horizontal: boolean;
}) {
  const c = props.channel;
  let label = c.keyIngestoid?.label;
  if (!props.horizontal) {
    label = label?.replace("/", "|").replace("\\", "|");
  }
  const offset = c.x0 * props.breadth;
  const size = (c.x1 - c.x0) * props.breadth;
  const left = props.horizontal ? undefined : offset;
  const top = props.horizontal ? offset : undefined;
  const color = getLabelColorForChannelColor(c.color);
  return (
    <div
      className={classnames({ "label-line": true, rotated: !props.horizontal })}
      data-channel-index={c.index}
      style={{ left, top, fontSize: size }}
    >
      <div
        style={{ color: color.hex() }}
        className={classnames("bungee-line", c.alarmClass)}
      >
        <div>{label}</div>
      </div>
    </div>
  );
});

const LabelsSheet = observer(function (props: {
  breadth: number;
  horizontal: boolean;
}) {
  const driver = React.useContext(DriverContext);

  const channels = driver.channels!;

  const visibleChannels = channels.getVisibleChannels();
  const haveLabels = channels.getHaveLabels();

  return (
    <div
      className={classnames("labels-sheet", { horizontal: props.horizontal })}
    >
      {haveLabels &&
        visibleChannels.map((c) => (
          <ChannelLabel
            key={c.index}
            channel={c}
            breadth={props.breadth}
            horizontal={props.horizontal}
          />
        ))}
    </div>
  );
});

const Teaser = function (props: {}) {
  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) return <></>;

  return (
    <div className="teaser">
      <Card className={Classes.INTENT_PRIMARY}>
        <Button
          icon="cross"
          minimal={true}
          className="float-right"
          large={true}
          onClick={() => setIsOpen(false)}
        />
        <h5>Twitter Demo Board</h5>
        <p>
          This board shows all tweets for a number of hashtags and search terms
          as they are tweeted in in real time.
        </p>
        <p>
          Due to traffic limitations of the Twitter API, you will have to wait
          about 5s before the activity starts. It will deactivate itself after
          running for a minute. Refreshing the page with start it again.
        </p>
        <p>
          The hashtags and search term names can be seen dimly in the channel
          background or clearly when hovering (on desktops). Clicking on a
          channel shows you the last message posted as ingested by Blipboard.
        </p>
      </Card>
    </div>
  );
};

const CustomResizeSensor = observer(function (props: {
  setSize: { (rect: DOMRectReadOnly): any };
  children: React.ReactNode;
}) {
  return (
    <ResizeSensor
      onResize={(es) => {
        const rect = es[0].contentRect;
        props.setSize(rect);
      }}
    >
      {props.children}
    </ResizeSensor>
  );
});

const Chrome = observer(function (props: {
  interactive: boolean;
  teaser?: string | null;
}) {
  const screenRef = useScreen();

  const driver = React.useContext(DriverContext);

  const [size, setSize] = React.useState<DOMRectReadOnly | null>(null);

  const chromeCursor = props.interactive ? undefined : "none";

  const haveLabels = driver.channels!.getHaveLabels().get() || false;

  const horizontal = viewerSettings.orientation == "r2l";

  const screenWidth = size?.width ?? 1;
  const screenHeight = size?.height ?? 1;
  const breadth = horizontal ? screenHeight : screenWidth;
  const length = !horizontal ? screenHeight : screenWidth;

  const displayDimensions: DisplayDimensions = {
    horizontal,
    breadth,
    length,
    screenWidth,
    screenHeight,
  };

  return (
    <CustomResizeSensor
      setSize={(rect) => {
        setSize(rect);
        driver.channels!.setWidth(rect.width);
      }}
    >
      <div
        className={classnames("chrome", {
          horizontal,
          presentable: driver.isPresentable,
        })}
        style={{
          cursor: chromeCursor,
          opacity: subdueColors ? subdueTotalOpacity : undefined,
        }}
      >
        {viewerSettings.tbgEnabled && <TemporalBackground />}
        <ScreenUnderlay width={size?.width ?? 1} height={size?.height ?? 1} />
        {debugLabels.get() != 1 && haveLabels && (
          <LabelsSheet breadth={breadth ?? 1} horizontal={horizontal} />
        )}
        <canvas ref={screenRef} className="screen" />
        {props.interactive && (
          <ScreenOverlay displayDimensions={displayDimensions} />
        )}
        {props.teaser && <Teaser />}
      </div>
    </CustomResizeSensor>
  );
});
