import logLevel from "loglevel";
import { Address, BoardSettings, formatAddress } from "./common";

const log = logLevel.getLogger("page");

export class PageEntry {
  constructor(public page: Page, public i: number, public address: Address) {}

  get count() {
    return this.page.getCount(this.i);
  }
  get flow() {
    return this.page.getFlow(this.i);
  }
  set count(value: number) {
    this.page.setCount(this.i, value);
  }

  get begin() {
    return this.page.getBegin(this.i);
  }
  set begin(value: number) {
    this.page.setBegin(this.i, value);
  }

  get end() {
    return this.page.getEnd(this.i);
  }
  set end(value: number) {
    this.page.setEnd(this.i, value);
  }

  get mode() {
    return this.page.getMode(this.i);
  }

  getSpeed(settings: BoardSettings) {
    return this.count / (1 << (this.address.expo + settings.resolutionLog2));
  }
}

export class Page {
  static BytesPerEntry = 32;

  private float64s: Float64Array;
  private int32s: Int32Array;
  private int16s: Int16Array;
  private uint8s: Uint8Array;
  private int16u: Uint16Array;
  private byteLength: number;
  constructor(
    public source: string,
    public address: Address,
    private expectedPageSize: number,
    buffer: ArrayBuffer
  ) {
    this.float64s = new Float64Array(buffer);
    this.int32s = new Int32Array(buffer);
    this.int16s = new Int16Array(buffer);
    this.int16u = new Uint16Array(buffer);
    this.uint8s = new Uint8Array(buffer);
    this.byteLength = expectedPageSize * Page.BytesPerEntry;
    if (buffer.byteLength < this.byteLength)
      throw Error(
        `Got ${buffer.byteLength} bytes for page, but expected ${this.byteLength}`
      );
    this.assertSanity();
  }
  assertSanity() {
    for (let i = 0; i < this.float64s.length; ++i) {
      const c = this.getCount(i) !== 0;
      const b = this.getBegin(i) !== 0;
      const e = this.getEnd(i) !== 0;
      if ((b || e) && !c) {
        throw Error(
          `Page ${formatAddress(
            this.address
          )} is not sane at channel #${i}: ${this.formatEntry(i)}`
        );
      }
    }
  }
  formatEntry(i: number) {
    return `[${i}:cnt=${this.getCount(i)},clr=${this.getColorIndex(
      i
    )},bgn=${this.getBegin(i)},end=${this.getEnd(i)}]`;
  }
  get report() {
    let s = 0;
    for (var i = 0; i < this.pageSize; ++i) {
      if (this.getCount(i) > 0) {
        s++;
      }
    }
    return `Page ${formatAddress(this.address)} with ${s} nontrivial entries`;
  }
  get isNonTrivial() {
    for (let i = 0; i < this.float64s.length; ++i) {
      if (this.getCount(i)) return true;
    }
    return false;
  }
  get pageSize() {
    return this.byteLength / Page.BytesPerEntry;
  }
  getEntry(i: number) {
    return new PageEntry(this, i, this.address);
  }
  getCount(i: number) {
    return this.float64s[i * 4];
  }
  getFlow(i: number) {
    return this.getCount(i) / (1 << this.address.expo);
  }
  getBegin(i: number) {
    return this.float64s[i * 4 + 1];
  }
  getEnd(i: number) {
    return this.float64s[i * 4 + 2];
  }
  getColorIndex(i: number) {
    // 10 bits for color
    return this.int16s[i * 16 + 12] & 0x3ff;
  }
  getFlags(i: number) {
    return this.int16u[i * 16 + 12] >> 10;
  }
  getOrderAndFirst(i: number) {
    return this.int16s[i * 16 + 13];
  }
  getOrder(i: number) {
    return Math.abs(this.getOrderAndFirst(i));
  }
  getIsFirst(i: number) {
    return this.getOrderAndFirst(i) < 0;
  }
  getIsRenamed(i: number) {
    return hasFlag(this.getFlags(i), entryFlags.isRenamed);
  }
  setCount(i: number, value: number) {
    this.float64s[i * 4] = value;
  }
  setBegin(i: number, value: number) {
    this.float64s[i * 4 + 1] = value;
  }
  setEnd(i: number, value: number) {
    this.float64s[i * 4 + 2] = value;
  }
  setColorIndex(i: number, value: number) {
    this.setColorIndexAndFlags(i, value, this.getFlags(i));
  }
  setFlags(i: number, flags: number) {
    this.setColorIndexAndFlags(i, this.getColorIndex(i), flags);
  }
  setColorIndexAndFlags(i: number, color: number, flags: number) {
    this.int16u[i * 16 + 12] = (color & 0x3ff) | (flags << 10);
  }
  setOrderAndFirst(i: number, value: number) {
    this.int16s[i * 16 + 13] = value;
  }
  getMode(i: number) {
    const flags = this.getFlags(i);
    if (hasFlag(flags, entryFlags.inValueMode)) {
      return "value";
    } else if (hasFlag(flags, entryFlags.inBlipMode) || this.getCount(i) > 0) {
      return "blip";
    } else {
      return undefined;
    }
  }
  accumulate(other: Page) {
    if (other.address.expo != this.address.expo)
      throw new Error("Only pages with the same exponent can be accumulated");
    if (other.address.manti != this.address.manti + 1)
      throw new Error("Only subsequent pages can be accumulated");
    const buffer = new ArrayBuffer(this.byteLength);
    const result = new Page(
      "local",
      { manti: this.address.manti >> 1, expo: this.address.expo + 1 },
      this.pageSize,
      buffer
    );
    const n = this.pageSize;
    for (let i = 0; i < n; ++i) {
      const c = this.getCount(i);
      const b = this.getBegin(i);
      const e = this.getEnd(i);
      const f = this.getIsFirst(i);
      const v = hasFlag(this.getFlags(i), entryFlags.inValueMode);
      const co = other.getCount(i);
      const bo = other.getBegin(i);
      const eo = other.getEnd(i);
      const ho = other.getColorIndex(i);
      const fo = other.getIsFirst(i);
      const vo = hasFlag(other.getFlags(i), entryFlags.inValueMode);

      let isValueMode: boolean;

      if (fo) {
        log.debug(`Skipping older half in a merge.`);
      }

      if (c > 0 && !fo) {
        isValueMode = v || vo;

        if (co > 0) {
          result.setCount(i, c + co);
          const bm = Math.min(b, bo);
          const em = Math.max(e, eo);
          result.setBegin(i, bm);
          result.setEnd(i, em);
        } else {
          result.setCount(i, c);
          result.setBegin(i, b);
          result.setEnd(i, e);
        }
      } else {
        isValueMode = vo;

        result.setCount(i, co);
        result.setBegin(i, bo);
        result.setEnd(i, eo);
      }

      result.setFlags(i, setFlag(0, entryFlags.inValueMode, isValueMode));

      // FIXME: I think it's wrong or at least unnecessary to set isFirst on the summaries here,
      // as the less summarized page already contains the information and it's obviously present.
      // this may also help with the spurious ingestoid requests

      result.setColorIndex(i, ho);
      result.setOrderAndFirst(i, other.getOrder(i) * (f || fo ? -1 : 1));
    }

    this.assertSanity();
    return result;
  }
}

const entryFlags = {
  isFirst: 1,
  inValueMode: 2,
  inBlipMode: 4,
  isRenamed: 8,

  hasFlags: 32,
};

function hasFlag(flags: number, flag: number) {
  return (flags & flag) !== 0;
}

function setFlag(flags: number, flag: number, value: boolean) {
  flags &= ~flag;
  if (value) {
    flags |= flag;
  }
  return flags;
}
