import { observable } from "mobx";
import { viewerSettings } from "./settings";

const localStorageKey = "last-geolocation";

interface GeoLocationCoords {
  latitude: number;
  longitude: number;
}

type GeoLocationInfo = GeoLocationCoords & {
  tzoffset: number;
  isUnreliable?: boolean;
};

type GeoLocationInquiryResult = "denied" | GeoLocationCoords;

const currentState = observable({
  inquiring: false,
  lastResult: loadFromStorage(),
});

export function haveGeoLocationInquiryResult() {
  return !!currentState.lastResult;
}

export function getGeoLocationInfo(): GeoLocationInfo {
  function getCoordsFromZoneOffset(tzoffset: number, latitude: number) {
    return {
      latitude,
      longitude: (tzoffset / 60) * 12,
      tzoffset,
      isUnreliable: true,
    };
  }

  const source = viewerSettings.geoLocationSource;

  switch (source) {
    case "browser":
    case "zoneoffset": {
      const tzoffset = -new Date().getTimezoneOffset();

      const current = currentState.lastResult;

      if (source === "browser" && current && current !== "denied") {
        return { ...current, tzoffset };
      } else {
        return getCoordsFromZoneOffset(
          tzoffset,
          viewerSettings.fallbackLatitude
        );
      }
    }
    case "Bochum":
      return { latitude: 51.48, longitude: 7.21, tzoffset: 1 * 60 };
    case "New York":
      return { latitude: 40.7128, longitude: -74.006, tzoffset: -5 * 60 };
    case "San Francisco":
      return { latitude: 37.7749, longitude: -122.4194, tzoffset: -8 * 60 };
    case "Greenwich":
      return { latitude: 51.5, longitude: 0, tzoffset: 0 };
    case "Wellington":
      return { latitude: 41.3, longitude: 174.8, tzoffset: 12 * 60 };
    case "Sydney":
      return { latitude: 33.87, longitude: 151.21, tzoffset: 10 * 60 };
    default:
      throw Error(`Unkown infoSource '${source}'`);
  }
}

function loadFromStorage(): GeoLocationInquiryResult | undefined {
  const storedString = localStorage.getItem(localStorageKey);

  if (storedString) {
    return JSON.parse(storedString) as GeoLocationInquiryResult;
  } else {
    return undefined;
  }
}

function saveToStorage(result: GeoLocationInquiryResult) {
  localStorage.setItem(localStorageKey, JSON.stringify(result));
}

export async function inquireAndUpdateGeolocationPosition() {
  if (!viewerSettings.interactive) return;

  if (currentState.inquiring) return;

  currentState.inquiring = true;

  try {
    currentState.lastResult = await inquireGeolocationPosition();
  } finally {
    currentState.inquiring = false;
  }

  saveToStorage(currentState.lastResult);
}

function inquireGeolocationPosition(): Promise<GeoLocationInquiryResult> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (p) => {
        const { latitude, longitude } = p.coords;

        resolve({ latitude, longitude });
      },
      (e) => resolve("denied")
    );
  });
}
