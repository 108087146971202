const sunCalc = `
// Based on SunCalc at rev 6bc2757074c1e5eaa2389aa2ab36806f04c6d2a0

uniform float lat, lng;

const float PI = 2. * 1.57079632679489661923132169163975144;
const float rad = PI / 180.;

const float
    dayMs = 1000. * 60. * 60. * 24.,
    J1970 = 2440588.,
    J2000 = 2451545.;

float toJulian(float date) { return date / dayMs - 0.5 + J1970; }
//float fromJulian(float j)  { return new Date((j + 0.5 - J1970) * dayMs); }
float toDays(float date)   { return toJulian(date) - J2000; }

// general calculations for position

float e = rad * 23.4397; // obliquity of the Earth

float rightAscension(float l, float b) { return atan(sin(l) * cos(e) - tan(b) * sin(e), cos(l)); }
float declination(float l, float b)    { return asin(sin(b) * cos(e) + cos(b) * sin(e) * sin(l)); }

float azimuth(float H, float phi, float dec)  { return atan(sin(H), cos(H) * sin(phi) - tan(dec) * cos(phi)); }
float altitude(float H, float phi, float dec) { return asin(sin(phi) * sin(dec) + cos(phi) * cos(dec) * cos(H)); }

float siderealTime(float d, float lw) { return rad * (280.16 + 360.9856235 * d) - lw; }

// general sun calculations

float solarMeanAnomaly(float d) { return rad * (357.5291 + 0.98560028 * d); }

float eclipticLongitude(float M) {

    float C = rad * (1.9148 * sin(M) + 0.02 * sin(2. * M) + 0.0003 * sin(3. * M)), // equation of center
        P = rad * 102.9372; // perihelion of the Earth

    return M + C + P + PI;
}

void sunCoords(float d, out float dec, out float ra) {

    float M = solarMeanAnomaly(d),
        L = eclipticLongitude(M);

        dec = declination(L, 0.);
        ra = rightAscension(L, 0.);
}

float getSunPosition(float date) {

    float
        lw  = rad * -lng,
        phi = rad * lat,
        d   = toDays(date);
    
    float dec, ra;

    sunCoords(d, dec, ra);

    float H  = siderealTime(d, lw) - ra;

    return altitude(H, phi, dec);
}

// calculations for sun times

float J0 = 0.0009;

float julianCycle(float d, float lw) { return round(d - J0 - lw / (2. * PI)); }

float approxTransit(float Ht, float lw, float n) { return J0 + (Ht + lw) / (2. * PI) + n; }
float solarTransitJ2(float ds, float M, float L)  {
    // The offset J2000 is taken out lest we hit float32's
    // precision limits. -- jens
    return /*J2000 +*/ ds + 0.0053 * sin(M) - 0.0069 * sin(2. * L);
}

float hourAngle(float h, float phi, float d) { return acos((sin(h) - sin(phi) * sin(d)) / (cos(phi) * cos(d))); }
float observerAngle(float height) { return -2.076 * sqrt(height) / 60.; }

// returns set time for the given sun altitude
float getSetJ(float h, float lw, float phi, float dec, float n, float M, float L) {

    float
        w = hourAngle(h, phi, dec),
        a = approxTransit(w, lw, n);

    return solarTransitJ2(a, M, L);
}

// We're only interested in the average daylight.

float getDaylight(float date) {

    float deg = 0.; // the type of dawn

    float height = 0.;

    float
        lw = rad * -lng,
        phi = rad * lat,

        dh = observerAngle(height),

        d = toDays(date),
        n = julianCycle(d, lw),
        ds = approxTransit(0., lw, n),

        M = solarMeanAnomaly(ds),
        L = eclipticLongitude(M),
        dec = declination(L, 0.),

        Jnoon = solarTransitJ2(ds, M, L),

        i, len, time, h0, Jset, Jrise;


    h0 = (deg + dh) * rad;

    Jset = getSetJ(h0, lw, phi, dec, n, M, L);
    Jrise = Jnoon - (Jset - Jnoon);

    return Jset - Jrise;
}
`;

export const temporalShader = `#version 300 es
precision highp float;

${sunCalc}

out vec4 outColor;
in vec2 v_position;

uniform float now;
uniform float t0;
uniform float t1;

uniform float halfPixelSize;

uniform float linearity;

uniform float zoneOffsetMillis;

uniform float tbgWeekendEmphazisOnFuzzyDays;
uniform float tbgPatternAlpha;

uniform bool featureWeekends;
uniform bool featureDaylight;

uniform sampler2D dawnGradient;

const float millisInDay = 24. * 3600. * 1000.;

float getRelTimeFromCoordLog(float x) {
    float l0 = log(t0);
    float l1 = log(t1);

    float f = 1.0 / (l1 - l0);
    float g = -l0 / (l1 - l0);

    return exp((x - g) / f);
}

float getTimeFromCoord(float x) {
    float tLog = getRelTimeFromCoordLog(x);
    float tLin = t0 - x * (t0 - t1);
    return now - mix(tLog, tLin, linearity);
}

float getDayBeginMillis(float tMillis) {
    return tMillis - mod(tMillis + zoneOffsetMillis, millisInDay);
}

float getDayness(vec4 times, float days) {
    return mod(days, 1.0);
}

vec4 getDayColor(float dayness) {
    return texture(dawnGradient, vec2(.0, dayness));
}

float scaleToUnit(float t, vec2 s) {
    return (t - s.x) / (s.y - s.x);
}

float easeInOutCubic(float x) {
    return x < 0.5 ? 4. * pow(x, 3.) : 1. - pow(-2. * x + 2., 3.) / 2.;
}

// We're easing to make the year cycle more pronounced
float exagerate(float t) {
    t = (t - .5) * 2. + .5;
    t = easeInOutCubic(t);
    return t;
}

const vec2 dawnDegrees = vec2(0., 6.);

const vec2 radiansToDayness = dawnDegrees /  360. * PI;

void main()
{
    // outColor = vec4(1., 0., 0., 1.);

    // return;

    outColor = getDayColor(.5);

    float x = .5 + .5 * v_position.x;    

    float tBeginMillis = getTimeFromCoord(x + halfPixelSize);
    float tEndMillis = getTimeFromCoord(x - halfPixelSize);

    if (tBeginMillis < 0.) return;

    float tMiddleMillis = (tEndMillis + tBeginMillis) / 2.0;
    float tDeltaMillis = tEndMillis - tBeginMillis;

    float tMiddleDays = tMiddleMillis / millisInDay;

    float tDayBeginMillis = getDayBeginMillis(tMiddleMillis);


    float tDayBeginDays = tDayBeginMillis / millisInDay;
    float tDeltaDays = tDeltaMillis / millisInDay;

    bool isWe = int(tDayBeginDays + 0.5 + 3.) % 7 >= 5;

    float sunPosition = getSunPosition(tMiddleMillis);

    float dayness = clamp(scaleToUnit(sunPosition, radiansToDayness), 0., 1.);
 
    float daylight = getDaylight(tMiddleMillis);

    daylight = exagerate(daylight);

    outColor = getDayColor(dayness);
    
    vec4 averageDayColor = getDayColor(daylight);

    float dayFuzziness = clamp(tDeltaDays * 4., 0., 1.);
    float weekFuzziness = clamp((tDeltaDays * 4.) / 7., 0., 1.);
    float yearFuzziness = clamp(
      (tDeltaMillis / millisInDay / 365.2425) * 1.,
      0.,
      1.
    );

    if (featureDaylight) {
        outColor = mix(outColor, averageDayColor, dayFuzziness);
    }

    if (featureWeekends && isWe) {
        outColor.a =
            1.0 -
            max(
                dayFuzziness * tbgWeekendEmphazisOnFuzzyDays,
                dayness
            ) *
                tbgPatternAlpha *
                (1.0 - weekFuzziness)
        ;
    }
}`;
