import logLevel from "loglevel";
import { useObservable } from "mobx-react-lite";
import { useState, useEffect } from "react";

(window as any)["logLevel"] = logLevel;

const log = logLevel.getLogger("token-fetch");

log.setLevel("info");

let exchangeDomain = "";

let installationSecret = "";

let currentToken = "";

let startup: undefined | (() => any);

export function getCurrentToken() {
  return currentToken;
}

export function getInstallationSecretOrNot() {
  return installationSecret;
}

export function getExchangeDomain() {
  if (!exchangeDomain) throw Error("No exchange host known yet");
  return exchangeDomain;
}

export function registerStartup(action: () => any) {
  if (startup) throw Error();

  registerMessageRetrieval();

  startup = action;
}

export function checkStartup() {
  if (startup) startup();
  startup = undefined;
}

export function authFetch(
  url: RequestInfo,
  doAuthFetch: boolean,
  init?: RequestInit
) {
  let init2 = init;
  if (doAuthFetch) {
    init2 = init || {};
    init2.credentials = "omit";
    init2.headers = init2.headers || {};
    (init2.headers as any)["Authorization"] = getCurrentToken();
  }
  return fetch(url, init2);
}

function registerMessageRetrieval() {
  window.addEventListener(
    "message",
    function (event) {
      var origin = event.origin;
      if (
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1" ||
        origin === "https://blipboard.io"
      ) {
        exchangeDomain = origin;

        if (typeof event.data == "object" && event.data.type == "token") {
          currentToken = event.data.value;

          checkStartup();

          log.info("Received token");
        } else if (
          typeof event.data == "object" &&
          event.data.type == "secret"
        ) {
          installationSecret = event.data.value;

          checkStartup();

          log.info("Received installation secret");
        } else {
          log.warn("Received incomprehensible message");
        }
      } else {
        log.error(`Received message from invalid origin '${origin}'`);
      }
    },
    false
  );
}

export function useFetch<T>(
  fetcher: () => Promise<T>,
  reaction?: (value: T) => void
) {
  const state = useObservable({
    loading: false,
    result: undefined as T | undefined,
    error: undefined as any,
    update: async () => {
      if (state.loading) throw Error("In request");
      try {
        state.loading = true;
        state.result = await fetcher();
        state.loading = false;
        if (reaction) reaction(state.result);
      } catch (ex) {
        state.error = ex;
        state.loading = false;
      }
    },
  });

  return state;
}
