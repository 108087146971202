import { Connection, HttpStatusCodeError } from "./connection";
import { Page } from "../page";
import {
  BoardSettings,
  Address,
  parseIngestoid,
  formatAddress,
  parseAddress,
  AlarmInfo,
} from "../common";
import {
  authFetch,
  getInstallationSecretOrNot,
  getExchangeDomain,
} from "../fetch";
import { Base64 } from "js-base64";
import logLevel from "loglevel";
import { getErrorOrUndefined } from "../util";

const debugLogDecryption = false;

const log = logLevel.getLogger("connection");

log.setDefaultLevel("info");

interface PreparedCryptoKeys {
  keyForIngestoids?: CryptoKey;
  ingestoidType?: string;
}

interface CryptoKeys {
  keyForLabels: string;
  keyForKeys: string;
}

interface AccessInfo {
  cryptoKeys: CryptoKeys;
  host: string;
}

const trivialInvisibleIngestoid = {
  label: ``,
};

export function makeNodeConnection(
  boardId: string,
  baseUrl: string
): Connection {
  let state:
    | {
        settings: BoardSettings;
        cryptoKeys?: PreparedCryptoKeys;
      }
    | undefined;

  function getBoardUrl(rest: string) {
    return `${baseUrl}/${boardId}/${rest}`;
  }

  async function decrypt(key: CryptoKey, cipher: Uint8Array) {
    const nonce = cipher.slice(-12);
    const cipherWithTag = cipher.slice(0, -12);

    if (debugLogDecryption) {
      const debugObject = {
        nonce: Base64.fromUint8Array(nonce),
        tag: Base64.fromUint8Array(cipherWithTag.slice(-12)),
        ciphertext: Base64.fromUint8Array(cipherWithTag.slice(0, -12)),
      };
      console.info(`decrypting with ${JSON.stringify(debugObject)}`);
    }

    return await crypto.subtle.decrypt(
      { name: "AES-GCM", iv: nonce },
      key,
      cipherWithTag
    );
  }

  async function makePreparedCryptoKeys(
    cryptoKeys: CryptoKeys
  ): Promise<PreparedCryptoKeys | undefined> {
    if (cryptoKeys.keyForKeys) {
      return {
        ingestoidType: `keys`,
        keyForIngestoids: await makeCrypoKey(cryptoKeys.keyForKeys),
      };
    } else if (cryptoKeys.keyForLabels) {
      return {
        ingestoidType: `labels`,
        keyForIngestoids: await makeCrypoKey(cryptoKeys.keyForLabels),
      };
    } else {
      return undefined;
    }
  }

  async function fetchIngestoid(
    path: string,
    key: CryptoKey | undefined,
    doAuthFetch: boolean,
    requestRefetch: boolean
  ) {
    const url = getBoardUrl(path);
    const response = await authFetch(url, doAuthFetch, {
      cache: requestRefetch ? "reload" : "default",
    });
    let buffer = await response.arrayBuffer();

    let bytes = new Uint8Array(buffer);

    if (key) {
      try {
        buffer = await decrypt(key, bytes);

        bytes = new Uint8Array(buffer);
      } catch (ex) {
        const allZero = bytes.every((v) => v === 0);

        const msg = allZero
          ? `Channel information is cleared`
          : `Could not decrypt ${buffer.byteLength} bytes - ${
              (ex as Error).toString() ?? "(no exception message)"
            }`;

        log.error(msg);
        return { parsingError: msg };
      }
    }

    const firstZeroI = bytes.indexOf(0);

    if (firstZeroI >= 0) {
      bytes = bytes.slice(0, firstZeroI);
    }

    const text = new TextDecoder().decode(bytes);

    return parseIngestoid(text);
  }

  async function makeCrypoKey(keyAsText: string) {
    if (!keyAsText) return undefined;

    if (debugLogDecryption) {
      console.info(`Using 32 bytes of key: ${keyAsText}`);
    }

    return await crypto.subtle.importKey(
      "raw",
      Base64.toUint8Array(keyAsText),
      { name: "AES-GCM", length: 32 },
      true, // FIXME
      ["decrypt", "encrypt"]
    );
  }

  async function fetchBoardInfo() {
    const response = await fetch(getBoardUrl(`info`), { cache: "no-cache" });

    if (!response.ok) {
      throw new HttpStatusCodeError(response.status);
    }

    return (await response.json()) as BoardSettings;
  }

  async function fetchCryptoKeysFromNode(): Promise<
    PreparedCryptoKeys | undefined
  > {
    const response = await authFetch(getBoardUrl(`crypto-keys`), true);

    if (!response.ok) {
      throw new HttpStatusCodeError(response.status);
    }

    const keys = (await response.json()) as CryptoKeys;

    return await makePreparedCryptoKeys(keys);
  }

  async function fetchCryptoKeysFromExchange(
    installationSecret: string
  ): Promise<PreparedCryptoKeys | undefined> {
    const response = await fetch(
      getExchangeDomain() +
        "/api/installation/access-info?installationSecret=" +
        installationSecret
    );

    if (!response.ok) {
      throw new HttpStatusCodeError(response.status);
    }

    const info = (await response.json()) as AccessInfo;
    const keys = info.cryptoKeys;

    return await makePreparedCryptoKeys(keys);
  }

  function fetchCryptoKeys() {
    const installationSecret = getInstallationSecretOrNot();

    if (installationSecret) {
      return fetchCryptoKeysFromExchange(installationSecret);
    } else {
      return fetchCryptoKeysFromNode();
    }
  }

  return {
    async fetchTime() {
      const response = await fetch(`${baseUrl}/time`, { cache: "no-cache" });

      const result = await response.arrayBuffer();

      const timeArray = new Float64Array(result);

      const systemTime = timeArray[0];
      return systemTime;
    },

    async fetchInitials() {
      state = {
        settings: await fetchBoardInfo(),
        cryptoKeys: await fetchCryptoKeys(),
      };

      return state!.settings;
    },

    async fetchAlarmInfo() {
      const response = await fetch(getBoardUrl(`alarms/info`), {
        cache: "no-cache",
      });

      if (!response.ok) {
        log.error("Can't fetch alarm info");

        return undefined;
      }

      return (await response.json()) as AlarmInfo;
    },

    async redeemBoard() {
      const response = await fetch(getBoardUrl(`alarms/redeem`), {
        cache: "no-cache",
        method: "GET",
      });

      if (!response.ok) throw new Error("Can't redeem");
    },

    async rearmChannel(channelI: number, arm: boolean) {
      const response = await fetch(
        getBoardUrl(`alarms/rearm?channelI=${channelI}&arm=${arm}`),
        {
          cache: "no-cache",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) throw new Error("Can't set channel alarm setting");
    },

    async deleteChannel(channelI: number) {
      const response = await authFetch(
        getBoardUrl(`channels/${channelI}`),
        true,
        {
          cache: "no-cache",
          method: "DELETE",
        }
      );
      if (!response.ok) throw new Error("Can't set channel alarm setting");
    },

    async fetchAddresses() {
      const response = await fetch(getBoardUrl(`current-addresses`));

      const addresses = (await response.json()) as string[];

      return addresses.map(parseAddress);
    },

    async fetchPage(address: Address) {
      if (!state) throw Error("No board settings loaded yet");

      const addressText = formatAddress(address);

      // The nonce is required for at least 3 days (broken large summaries may be in some cache)
      const response = await fetch(
        getBoardUrl(`medi/pages/${addressText}?none=1`)
      );

      if (response.status === 503) {
        log.warn(`Server signalled internal delay.`);

        return undefined;
      } else if (response.status == 404) {
        const missingPageReason = await response.text();
        log.info(
          `At ${addressText}: 404 (${missingPageReason?.toLowerCase()})`
        );

        if (missingPageReason === "Premature") {
          log.warn("Premature page fetch!");
        }

        return undefined;
      } else if (response.status != 200) {
        throw new Error(`Response status code ${response.status}`);
      }

      const result = await response.arrayBuffer();

      const pageSize = state!.settings.pageSize;

      const page = new Page("remote", address, pageSize, result);

      return page;
    },

    async fetchIngestoid(
      channelI: number,
      since: number,
      requestRefetch: boolean
    ) {
      if (debugLogDecryption) {
        console.info(`Fetching channel #${channelI}`);
      }

      if (state?.cryptoKeys?.ingestoidType) {
        return await fetchIngestoid(
          `${state?.cryptoKeys.ingestoidType}/channels/${channelI}?since=${since}`,
          state!.cryptoKeys.keyForIngestoids,
          false,
          requestRefetch
        );
      } else {
        return trivialInvisibleIngestoid;
      }
    },

    fetchBody(channelI: number) {
      return fetchIngestoid(
        `bodies/channels/${channelI}`,
        undefined,
        true,
        true
      );
    },
  };
}
