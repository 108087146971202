import { Page } from "../page";
import { BoardSettings, Address, Ingestoid, AlarmInfo } from "../common";

export class HttpStatusCodeError extends Error {
  constructor(public status: number) {
    super(`Server returned ${status}`);
  }
}

export interface Connection {
  fetchTime(): Promise<number>;

  fetchInitials(): Promise<BoardSettings>;

  fetchAlarmInfo(): Promise<AlarmInfo | undefined>;

  redeemBoard(): Promise<any>;

  rearmChannel(channelI: number, rearm: boolean): Promise<any>;

  deleteChannel(channelI: number): Promise<any>;

  fetchAddresses(): Promise<Address[]>;

  fetchPage(address: Address): Promise<Page | undefined>;

  fetchIngestoid(
    channelI: number,
    since: number,
    requestRefetch: boolean
  ): Promise<Ingestoid>;

  fetchBody(channelI: number): Promise<Ingestoid>;
}
