import { Page, PageEntry } from "./page";

export type ViewerOrientation = "t2b" | "r2l";

export function isViewerOrientationHorizontal(orientation: ViewerOrientation) {
  switch (orientation) {
    case "t2b":
      return false;
    case "r2l":
      return true;
    default:
      throw Error(`Unkown orientation ${orientation}`);
  }
}

export interface Control {
  type: number;
}

export interface Blip {
  key: string;

  laneTime: number;

  timeBegin: number;
  timeEnd: number;

  debug: string;

  count: number;

  maxSystemChannels: number;
  maxUserChannels: number;

  isSystem: boolean;

  control: Control | null;

  details: string; // deprecated
}

export interface NameUpdate {
  name: string;
  index: number;
  time: number;
}

export interface ScaleSettings {
  timeBegin: number;
  timeEnd: number;

  displayBegin: number;
  displayEnd: number;
}

export interface Context {
  time: number;
  onClick: (blip: Blip) => void;
  onHover: (blip?: Blip) => void;
}

export interface Address {
  manti: number;
  expo: number;
}

export function getSimulationTimeFromAddress(address: Address, end?: boolean) {
  return (address.manti + (end ? 1 : 0)) << address.expo;
}

export function parseAddress(text: string) {
  const parts = text.split("-");
  if (parts.length !== 2) throw Error("Invalid address");
  const expo = parseInt(parts[0], 16);
  const manti = parseInt(parts[1], 16);
  return { manti, expo };
}

export function formatAddress(address: Address) {
  const expo = address.expo.toString(16).padStart(2, "0");
  const manti = address.manti.toString(16).padStart(8, "0");
  return `${expo}-${manti}`;
}

export function delay(millis: number) {
  return new Promise<void>((resolve, reject) => setTimeout(resolve, millis));
}

export interface DriverObserver {
  init(settings: BoardSettings): void;

  reset(): void;

  addPage(address: Address, page?: Page): void;

  removePage(address: Address): void;
}

export interface LaneCell {
  address: Address;
  pageValue: PageEntry;
  cellSeconds: number;
  blipSeconds: number;
  isRatherSpreadOut: boolean;
}

export interface BoardSettings {
  name: string;
  usesAlarms: boolean;
  time: number;
  unixTimeBeginTime: number;
  resolutionLog2: number;
  noOfSystemChannels: number;
  noOfGenericSystemChannels: number;
  pageSize: number;
  pagesPerShift: number;
  maxShift: number;
  viewerSettingsJson: string;
}

export interface AlarmInfo {
  channelEntries: {
    isAlarmed: boolean;
    alarmedTime: number;
    alarmType: number;
  }[];
  lastAlarmedTime: number;
  lastRedeemedTime: number;
}

export interface Ingestoid {
  label?: string;
  level?: string;
  message?: string;
  msg_template?: string;
  unit?: string;
  format?: string;
  details?: string;
  color?: string;
  mode?: string;

  json?: string;
  pretty?: string;
  parsingError?: string;
}

export enum CurtainStatus {
  Connecting,
  Loading,
  Loaded,
  Stepping,
  AccessDenied,
  NoBoard,
  Got503,
  NoServer,
  NoNet,

  AboutToReload,
}

export function makeTimeConversion(settings: BoardSettings) {
  const upstreamStepMillis = Math.pow(2, settings.resolutionLog2) * 1000;

  return {
    getAddressFromMillis(relativeTime: number, expo: number, ceil?: boolean) {
      const stepMillis = upstreamStepMillis * (1 << expo);
      const round = ceil ? Math.ceil : Math.floor;
      const manti = round(relativeTime / stepMillis);
      return { manti, expo };
    },

    getAddressFromUnixTime(unixTime: number, expo: number, ceil?: boolean) {
      return this.getAddressFromMillis(
        unixTime - settings.unixTimeBeginTime,
        expo,
        ceil
      );
    },

    getMillisFromAddress(address: Address, end?: boolean) {
      return (
        ((address.manti + (end ? 1 : 0)) << address.expo) * upstreamStepMillis
      );
    },

    getUnixTimeFromAddress(address: Address, end?: boolean) {
      return (
        settings.unixTimeBeginTime + this.getMillisFromAddress(address, end)
      );
    },
  };
}

export function GetCurtainStatusString(status: CurtainStatus) {
  switch (status) {
    case CurtainStatus.Connecting:
      return "Connecting...";
    case CurtainStatus.Loading:
    case CurtainStatus.Loaded:
      return "Loading...";
    case CurtainStatus.Stepping:
      return "Stepping...";
    case CurtainStatus.AccessDenied:
      return "Access denied";
    case CurtainStatus.NoBoard:
      return "Board unreachable";
    case CurtainStatus.Got503:
    case CurtainStatus.NoServer:
      return "Server unreachable";
    case CurtainStatus.NoNet:
      return "No network access";
    case CurtainStatus.AboutToReload:
      return "Reached board again, reloading now...";
    default:
      return `${status}`;
  }
}

export function GetCurtainShouldBeOpen(status: CurtainStatus) {
  switch (status) {
    case CurtainStatus.Stepping:
      return true;
    case CurtainStatus.Loading:
    case CurtainStatus.Loaded:
      return undefined;
    default:
      return false;
  }
}

export function parseIngestoid(json: string) {
  let ingestion = {} as Ingestoid;

  try {
    const text = json.replace("\u0019", "\u2026");
    const o = (ingestion = JSON.parse(text) as Ingestoid);
    ingestion.pretty = JSON.stringify(o, undefined, 4);
    ingestion.json = json;
  } catch (ex) {
    ingestion = { parsingError: (ex as Error).message, json: json };
  }

  return ingestion;
}

export const reservedSystemChannelIndexes = {
  alarmNotification: 1,
};

export const art = Date.now();
