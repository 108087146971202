import React from "react";
import ReactDom from "react-dom";
import "@blueprintjs/core/lib/css/blueprint.css";
import "./style.css";
import "./custom.scss";
import logLevel from "loglevel";
import { registerStartup, checkStartup } from "./fetch";
import { FocusStyleManager } from "@blueprintjs/core";
import { makeNodeConnection } from "./connections/node-connection";
import { makeDemoConnection } from "./connections/demo-connection";
import { App } from "./chrome";
import { localSettings } from "./settings";

logLevel.setDefaultLevel("info");

const log = logLevel.getLogger("root");

const root = document.getElementById("root");

const urlParams = new URLSearchParams(window.location.search);
const boardId = urlParams.get("boardId");
const baseUrl = urlParams.get("baseUrl");
const demo = urlParams.get("demo");

export const areDevFeaturesEnabled = !!urlParams.get("devFeatures");

const interactive = (localSettings.interactive =
  !urlParams.get("noninteractive"));

const teaser = urlParams.get("teaser");

const styleSheet = urlParams.get("styleSheet");

if (styleSheet) {
  const styleLink = document.createElement("link");
  styleLink.setAttribute("rel", "stylesheet");
  styleLink.setAttribute("href", styleSheet);
  document.head.appendChild(styleLink);
}

const Usage = () => <div>Please use the baseUrl and boardId parameters.</div>;

FocusStyleManager.onlyShowFocusOnTabs();

if (demo) {
  localSettings.chromacity = 5;

  registerStartup(() => {
    const demoChannels = Number.parseInt(urlParams.get("demoChannels") ?? "0");

    const connection = makeDemoConnection(demoChannels);

    ReactDom.render(
      <App
        connection={connection}
        interactive={interactive}
        hideCurtain={true}
        teaser={teaser}
      />,
      root
    );
  });

  checkStartup();
} else if (boardId && baseUrl) {
  registerStartup(() => {
    const connection = makeNodeConnection(boardId, baseUrl);

    ReactDom.render(
      <App connection={connection} interactive={interactive} teaser={teaser} />,
      root
    );
  });
} else {
  ReactDom.render(<Usage />, root);
}

log.info("Blipboard Viewer Application loaded");
