import * as React from "react";

import { Icon, Spinner } from "@blueprintjs/core";
import { useLocalStore } from "mobx-react";
import { delay } from "./common";

export interface IOperationDescription {
  busyMessage: string;
  successMessage?: string;
  failureMessage?: string;
  delayMillis?: number;
  operation: () => Promise<any>;
}

export interface IOperationRunnerState {
  isBusy: boolean;
  exception: any;
  description?: IOperationDescription;
}

export function useOperationRunner() {
  const state = useLocalStore(() => ({
    isBusy: false,
    exception: undefined as any,
    description: undefined as IOperationDescription | undefined,
  }));

  const run = async (description: IOperationDescription) => {
    if (state.isBusy) return;

    state.isBusy = true;
    state.exception = undefined;
    state.description = description;
    try {
      await description.operation();
    } catch (ex) {
      state.exception = ex;
      console.info("ex: " + ex);
    } finally {
      state.isBusy = false;
    }

    await delay(description.delayMillis ?? 2000);

    state.description = undefined;
  };

  return { state, run };
}

export function OperationRunnerDisplay(state: IOperationRunnerState) {
  const desc = state.description;

  if (!desc) return <></>;

  const message = state.isBusy
    ? desc.busyMessage
    : state.exception
    ? desc.failureMessage
    : desc.successMessage;

  return (
    <div style={{ display: "flex", fontWeight: "bold" }}>
      <div style={{ width: 30 }}>
        {state.isBusy && <Spinner size={20} />}
        {!state.isBusy && (
          <Icon
            style={{ marginLeft: 7 }}
            icon={state.exception ? "warning-sign" : "tick"}
          />
        )}
      </div>
      {message}
    </div>
  );
}
