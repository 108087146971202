import { observable, runInAction } from "mobx";
import logLevel from "loglevel";
import * as Mousetrap from "mousetrap";
import { Page } from "./page";
import {
  BoardSettings,
  delay,
  Address,
  formatAddress,
  DriverObserver,
  CurtainStatus,
  GetCurtainStatusString,
  makeTimeConversion,
  LaneCell,
  reservedSystemChannelIndexes,
} from "./common";
import { PageCabinet } from "./cabinet";
import { scale } from "./board";
import { createChannels, Channel } from "./channels";
import { Connection, HttpStatusCodeError } from "./connections/connection";
import { getColorFromIndex } from "./color";
import { setBoardViewerSettings, viewerSettings } from "./settings";
import { inquireAndUpdateGeolocationPosition } from "./geolocation";

const log = logLevel.getLogger("driver");

log.setLevel("info");

const plannedDiscrepancy = 1500;
const synchronizeTimeEvery = 8;

export class Driver {
  message = "";

  timeConversion?: ReturnType<typeof makeTimeConversion>;

  @observable
  discrepancy = 0;

  @observable
  boardSettings?: BoardSettings;

  @observable
  lastPageFetched?: Page;

  @observable
  expo = 0;

  displayedPages: { address: Address; page?: Page }[] = [];

  displayedAddresses: Set<string> = new Set();

  @observable
  channels?: ReturnType<typeof createChannels>;

  cabinet?: PageCabinet;

  oldestPagesByShift: Page[] = [];

  @observable
  isPresentable = false;

  @observable
  isPaused = false;

  @observable
  status = CurtainStatus.Connecting;
  @observable
  internalStatus = "";

  // shouldn't live here
  @observable
  visualNow: number;

  private observer?: DriverObserver;

  constructor(private connection: Connection) {
    this.visualNow = this.now;
    Mousetrap.bind("p", () => {
      this.isPaused = !this.isPaused;
    });
  }

  subscribe(observer: DriverObserver) {
    if (this.observer) throw Error("Already observed.");

    if (this.lastPageFetched) {
      log.error("Subscribing to driver, but page already exists");
    }

    this.observer = observer;
    if (this.boardSettings) {
      observer.init(this.boardSettings!);
    }
    return () => {
      this.observer = undefined;
    };
  }

  get nonFixedChannels() {
    const bs = this.boardSettings!;
    return bs.pageSize - bs.noOfSystemChannels + bs.noOfGenericSystemChannels;
  }

  get effectiveDiscrepancy() {
    return plannedDiscrepancy + this.discrepancy;
  }

  get now() {
    return Date.now() - this.effectiveDiscrepancy;
  }

  private setStatus(status: CurtainStatus, internal?: string) {
    internal = internal ?? "";
    if (this.status !== status || this.internalStatus !== internal) {
      log.info(
        `Status change: ${GetCurtainStatusString(status)} (${internal})`
      );
      this.status = status;
      this.internalStatus = internal;
    }
  }

  async start() {
    try {
      await this.start2();
    } catch (ex) {
      if (ex instanceof HttpStatusCodeError && ex.status === 503) {
        this.setStatus(CurtainStatus.Got503);
      } else if (ex instanceof Error) {
        log.warn(`Unhandled exception, lowering curtain: ${ex.message}`);
      } else {
        log.warn(`Unhandled exception, lowering curtain.`);
      }
      this.stop();
      return;
    }
  }

  async start2() {
    this.setStatus(CurtainStatus.Connecting);

    const settings = await this.connection.fetchInitials();

    setBoardViewerSettings(settings.viewerSettingsJson);

    this.channels = createChannels(settings);

    this.boardSettings = settings;

    this.observer?.init(this.boardSettings);

    this.discrepancy = Date.now() - this.boardSettings.time;

    this.cabinet = new PageCabinet(
      this.boardSettings.maxShift,
      this.boardSettings.pagesPerShift,
      (p) => runInAction(() => this.updateChannelsFromPage(p))
    );

    this.timeConversion = makeTimeConversion(this.boardSettings);

    let lastPageFetchResult: Page | undefined = undefined;

    this.fetchAlarmState();

    while (!this.isPaused) {
      try {
        if (lastPageFetchResult) {
          this.updateDisplayedPages();

          this.isPresentable = true;

          this.setStatus(CurtainStatus.Stepping);
        } else {
          this.setStatus(CurtainStatus.Loading);

          await this.loadFull();

          if (!this.lastPageFetched)
            throw Error("loadFull didn't load even one page");

          this.setStatus(CurtainStatus.Loaded);

          await this.synchronizeTime();

          if (viewerSettings.inquireGeolocationPosition) {
            inquireAndUpdateGeolocationPosition();
          }
        }

        if (!lastPageFetchResult) {
          await delay(500);
        } else {
          this.channels!.setNow(
            this.timeConversion.getUnixTimeFromAddress(
              lastPageFetchResult.address
            )
          );
        }

        lastPageFetchResult = await this.loadStep();

        if (this.lastPageFetched!.address.manti % synchronizeTimeEvery === 0) {
          await this.synchronizeTime();
        }

        this.requestEnsureAllKeys(); // no await

        await this.updateAlarmsIfRequired(lastPageFetchResult);
      } catch (ex) {
        log.warn(`Leaving run on exception: ${ex}`);

        throw ex;
      }
    }
  }

  async stop() {
    while (true) {
      try {
        await delay(5000);

        break;
      } catch (ex) {
        if (ex instanceof HttpStatusCodeError) {
          if (ex.status == 503) {
            this.setStatus(CurtainStatus.Got503);
          } else if (ex.status == 500) {
            this.setStatus(CurtainStatus.NoServer);
          } else if (ex.status == 404) {
            this.setStatus(CurtainStatus.NoBoard);
          } else if (ex.status == 403) {
            this.setStatus(CurtainStatus.AccessDenied);
          } else {
            this.setStatus(CurtainStatus.NoServer, `status ${ex.status}`);
          }
        } else {
          throw ex;
        }

        try {
          await fetch("https://google.com", { mode: "no-cors" });

          this.setStatus(CurtainStatus.NoServer, `Exception: ${ex.message}`);
        } catch (ex2) {
          this.setStatus(CurtainStatus.NoNet, `google can't be reached`);
        }
      }
    }

    this.setStatus(CurtainStatus.AboutToReload);

    await delay(2000);

    document.location.reload();
  }

  async fetchAlarmState() {
    if (!this.boardSettings?.usesAlarms) return;

    const alarmInfo = await this.connection.fetchAlarmInfo();

    log.info(`Fetched new alarm state`);

    if (!alarmInfo) return false;

    const channels = this.channels!.getAllChannels();

    for (let i = 0; i < channels.length; ++i) {
      const channel = channels[i];

      const entry = alarmInfo.channelEntries[i];

      const getAlarmClass = () => {
        if (entry.alarmType === 0) return "alarmed-none";
        if (entry.alarmedTime < alarmInfo.lastRedeemedTime) {
          return entry.isAlarmed ? "alarmed-redeemed" : "alarmed-rearmed";
        }
        return "alarmed-newly";
      };

      channel.alarmClass = getAlarmClass();
    }

    return alarmInfo.lastAlarmedTime > alarmInfo.lastRedeemedTime;
  }

  async redeemAlarms() {
    await this.connection.redeemBoard();

    await this.fetchAlarmState();
  }

  async setChannelAlarm(channelI: number, isArmed: boolean) {
    await this.connection.rearmChannel(channelI, isArmed);

    await this.fetchAlarmState();
  }

  async deleteChannel(channelI: number) {
    await this.connection.deleteChannel(channelI);
  }

  /**
   * Algo:
   *
   * There are two loading phases:
   * - Full loading and
   * - Step loading
   *
   * Full loading is done when the next address to load for
   * the current local cabinet is no longer available on the server,
   * which is also the case on initial load.
   *
   * Otherwise, step loading loads the next address for the current
   * cabinet, usually after waiting until a time when it's likely
   * available. When delayed, the client could load a couple of pages
   * without intermediate waiting, ie. it's going to sprint.
   *
   */

  async loadFull() {
    const addresses = await this.connection.fetchAddresses();

    await Promise.all(addresses.map((a) => this.fetchPage(a)));
  }

  async loadStep() {
    const address = {
      expo: this.expo,
      manti: this.lastPageFetched!.address.manti + 1,
    };
    const timeToRequest = this.timeConversion!.getUnixTimeFromAddress(address);

    const gap = timeToRequest - this.now;

    log.debug(`gap is ${gap}ms`);

    if (gap > 0) {
      await delay(gap);
    }

    return await this.fetchPage(address, true);
  }

  private async updateDisplayedPages() {
    if (this.isPaused) return;

    const newDisplayedPages: { address: Address; page?: Page }[] = [];
    if (this.lastPageFetched) {
      scan2(
        this.lastPageFetched.address,
        this.boardSettings!.pagesPerShift,
        this.boardSettings!.maxShift,
        (address) => {
          const page = this.getPage(address);
          newDisplayedPages.push({ address, page });
        }
      );
    }

    const newAddressesToDisplay = new Set(
      newDisplayedPages.map((p) => formatAddress(p.address))
    );

    const log = logLevel.getLogger("displayedPages");
    log.setLevel("warn");

    log.debug(`-- Updating displayed pages`);

    for (let page of this.displayedPages) {
      if (!newAddressesToDisplay.has(formatAddress(page.address))) {
        log.debug(`Removing address ${formatAddress(page.address)}`);
        this.observer!.removePage(page.address);
      }
    }

    for (let page of newDisplayedPages) {
      if (!this.displayedAddresses.has(formatAddress(page.address))) {
        log.debug(`Adding address ${formatAddress(page.address)}`);
        this.observer!.addPage(page.address, page.page);
      }
    }

    this.displayedAddresses = newAddressesToDisplay;
    this.displayedPages = newDisplayedPages;
  }

  async synchronizeTime() {
    const systemTime = await this.connection.fetchTime();

    this.discrepancy = Date.now() - systemTime;

    log.debug(`Discrepancy now ${this.discrepancy}`);
  }

  async fetchPage(address: Address, makeSummaries?: boolean) {
    if (address.manti < 0) throw Error("Negative manti can't be fetched.");

    const addressText = formatAddress(address);

    const existingPage = this.cabinet!.getPage(address);

    if (existingPage) {
      log.debug(`At ${addressText}: already in cabinet`);

      return undefined;
    }

    const page = await this.connection.fetchPage(address);

    if (!page) return undefined;

    if (address.expo === this.expo) {
      if (page.address.expo !== this.expo)
        throw Error("Expected a floor expo.");

      if ((this.lastPageFetched?.address.manti ?? -1) < address.manti) {
        this.lastPageFetched = page;
      }
    }

    if (makeSummaries) {
      this.cabinet!.filePageWithSummaries(page);
    } else {
      this.cabinet!.filePage(page);
    }

    log.debug(`At ${addressText}: loaded`);

    return page;
  }

  getPage(address: Address) {
    if (address.manti < 0) return undefined;

    return this.cabinet!.getPage(address);
  }

  async updateAlarmsIfRequired(page?: Page) {
    const alarmChannelI = this.getReservedSystemChannelIndex(
      reservedSystemChannelIndexes.alarmNotification
    );

    if (page && page.getCount(alarmChannelI) > 0) {
      console.info(`Alarm state change notified`);
      await this.fetchAlarmState();
    }
  }

  getReservedSystemChannelIndex(i: number) {
    const s = this.boardSettings!;
    return s.pageSize - s.noOfSystemChannels + s.noOfGenericSystemChannels + i;
  }

  updateChannelsFromPage(page: Page) {
    // this function can be called in any order of addresses

    const beginTime = this.timeConversion!.getUnixTimeFromAddress(page.address);

    let resetCount = 0;

    // Remaining bug:
    // On initial loading the pages don't come in a defined order. It's possible that
    // an isFirst with an earlier address resets a minValue/maxValue range at a later range,
    // leading to an out-of-bounds curve. It will rectify itself on summary creation though.

    for (let i = 0; i < page.pageSize; ++i) {
      const channel = this.channels!.getChannel(i);
      if (!channel)
        throw Error(
          `Unexpectedly no channel at ${i} (page size is ${page.pageSize})`
        );
      if (page.getCount(i) > 0) {
        channel.latestTime = Math.max(beginTime, channel.latestTime);
      }
      channel.order = page.getOrder(i);
      const hasContent = page.getCount(i) > 0;
      if (beginTime > channel.resetTime) {
        const entryMode = page.getMode(i) ?? channel.mode;
        if (page.getIsFirst(i)) {
          ++resetCount;
          channel.renameTime = channel.resetTime = beginTime;
          channel.hasContent = hasContent;
          if (hasContent) {
            channel.hasStaleKeyIngestoid = true;

            channel.mode = entryMode;
            if (channel.mode === "blip") {
              channel.maxValue = channel.minValue = undefined;
            } else if (channel.mode === "value") {
              // Comparing against existing values those could come from future addresses.
              // We're chosing between two bugs here.
              channel.minValue = Math.min(
                channel.minValue ?? Number.MAX_VALUE,
                page.getBegin(i)
              );
              channel.maxValue = Math.max(
                channel.maxValue ?? Number.MIN_VALUE,
                page.getEnd(i)
              );
            }
          }
        } else if (hasContent) {
          if (channel.mode === "value" && channel.mode === entryMode) {
            channel.minValue = Math.min(
              channel.minValue ?? Number.MAX_VALUE,
              page.getBegin(i)
            );
            channel.maxValue = Math.max(
              channel.maxValue ?? Number.MIN_VALUE,
              page.getEnd(i)
            );
          }
        }

        if (page.getIsRenamed(i) && beginTime > channel.renameTime) {
          channel.renameTime = beginTime;
          channel.hasStaleKeyIngestoid = true;
        }
      }
      const colorIndex = page.getColorIndex(i);
      if (channel.colorIndex !== colorIndex) {
        channel.color = getColorFromIndex(
          page.getColorIndex(i),
          channel.layoutOrder,
          channel.index
        );
        channel.colorIndex = colorIndex;
      }
    }

    if (resetCount > page.pageSize / 2) {
      console.info(
        `${resetCount} channels reset at ${beginTime} from ${formatAddress(
          page.address
        )}`
      );
    }

    this.channels!.layoutChannels();
  }

  // Unused, but can be used to have pages where the lanes are nonempty
  // if and only if the lane index is the page's expo.
  makeIndicationPage(address: Address) {
    const buffer = new ArrayBuffer(this.boardSettings!.pageSize * 3 * 8);

    const page = new Page(
      "indication",
      address,
      this.boardSettings!.pageSize,
      buffer
    );

    const i = address.expo;
    page.setCount(i, 1);
    page.setBegin(
      i,
      this.timeConversion!.getUnixTimeFromAddress(address, false)
    );
    page.setEnd(i, this.timeConversion!.getUnixTimeFromAddress(address, true));

    return page;
  }

  requestEnsureAllKeys() {
    const channels = this.channels!.getAllChannels();

    for (let channel of channels) {
      this.ensureLatestKey(channel);
    }
  }

  public async ensureLatestKey(
    channel: Channel,
    requestRefetch?: boolean
  ): Promise<void> {
    if (!channel.hasContent) return;

    if (!channel.hasStaleKeyIngestoid && !requestRefetch) return;

    if (channel.ingestoidRequestInFlight) return;

    channel.ingestoidRequestInFlight = true;

    try {
      channel.keyIngestoid = await this.connection.fetchIngestoid(
        channel.index,
        channel.renameTime,
        requestRefetch ?? false
      );
    } finally {
      channel.ingestoidRequestInFlight = false;
    }

    channel.hasStaleKeyIngestoid = false;
  }

  public async loadBody(channel: Channel) {
    channel.bodyIngestoid = await this.connection.fetchBody(channel.index);
  }

  getLaneCellFromTime(
    channelI: number,
    relativeTime: number
  ): LaneCell | undefined {
    const settings = this.boardSettings!;
    let address = this.timeConversion!.getAddressFromUnixTime(
      this.visualNow - relativeTime,
      0,
      false
    );

    let page;
    do {
      page = this.cabinet!.getPage(address);
      address = { manti: address.manti >> 1, expo: address.expo + 1 };
      if (address.expo >= settings.maxShift) return undefined;
    } while (!page);

    const pageValue = page.getEntry(channelI);
    const cellSeconds = 1 << (address.expo + settings.resolutionLog2);
    const blipSeconds =
      pageValue.mode !== "value"
        ? (pageValue.end - pageValue.begin) / 1000
        : cellSeconds;

    const isRatherSpreadOut = blipSeconds > cellSeconds * 0.1;

    return { address, pageValue, cellSeconds, blipSeconds, isRatherSpreadOut };
  }

  getPercentageFromTime(unixTime: number) {
    const absoluteTime = this.visualNow - unixTime;
    return scale.scale(absoluteTime);
  }
}

function scan2(
  startAddress: Address,
  n: number,
  maxShift: number,
  putAddress: (address: Address) => any
) {
  let { manti, expo } = startAddress;

  let skippedLast = 0,
    hadPrevious = false;

  while (expo < maxShift) {
    const k0 = hadPrevious ? n / 2 : n;

    const k1 = skippedLast ? k0 + 1 : k0;

    // shall we skip the last page from the current shift as
    // we don't have both pages making up a whole summary?
    const skipLast = (manti - k1) % 2 === 0 ? 1 : 0;

    // number of pages to take from the current shift
    const k = k1 - skipLast;

    for (let i = 0; i < k; ++i, --manti) {
      putAddress({ manti, expo });
    }

    if (manti % 2 === 0) throw Error("Expected an odd manti.");

    manti = (manti - 1) >> 1;
    expo = expo + 1;

    skippedLast = skipLast;
    hadPrevious = true;
  }
}
