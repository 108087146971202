import * as React from "react";
import { Ingestoid } from "./common";
import MarkdownIt from "markdown-it";

const md = MarkdownIt();

function DetailsDisplayContent(props: Ingestoid) {
  switch (props.format) {
    case undefined:
    case "markdown":
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: (props.details && md.render(props.details)) || "",
          }}
        ></div>
      );
    case "plaintext":
    default:
      return <div className="details-preformatted">{props.details}</div>;
  }
}

export function DetailsDisplay(props: Ingestoid) {
  return (
    <div className="drawer-content-details">
      <DetailsDisplayContent {...props} />
    </div>
  );
}
