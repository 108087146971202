import { Page } from "./page";
import { Address } from "./common";

export class PageCabinet {
  private pages: Page[][];
  constructor(
    private maxShift: number,
    private pagesPerShift: number,
    private beforePageSet?: (page: Page) => unknown
  ) {
    this.pages = [];
    for (let i = 0; i < maxShift; ++i) {
      this.pages[i] = [];
    }
  }
  getIndex(address: Address) {
    return (address.manti + this.pagesPerShift) % this.pagesPerShift;
  }
  getPage(address: Address) {
    const page = this.pages[address.expo][this.getIndex(address)];
    if (!page || page.address.manti != address.manti) return undefined;
    return page;
  }
  setPage(page: Page) {
    if (page.address.manti < 0) throw Error("Expected nonnegative manti");

    const address = page.address;
    const formerPage = this.pages[address.expo][this.getIndex(address)];
    this.pages[address.expo][this.getIndex(address)] = page;
    return formerPage;
  }

  filePage(page: Page) {
    if (this.beforePageSet) this.beforePageSet(page);

    this.setPage(page);
  }

  filePageWithSummaries(page: Page) {
    this.filePage(page);

    this.createSummaries(page);
  }

  createSummaries(page: Page) {
    const shift = page.address.expo;

    if ((page.address.manti & 1) == 1 && shift < this.maxShift - 1) {
      // create summary page for address.time and address.time & ~1

      var t0 = page.address.manti & ~1;

      var a0 = { manti: t0, expo: shift };

      const p0 = this.getPage(a0);
      const p1 = this.getPage(page.address);

      if (!p0) return;
      if (!p1) throw Error("Expected current page to be in the cabinet.");

      const newPage = p0.accumulate(p1);

      this.filePageWithSummaries(newPage);
    }
  }
}
