import { Connection } from "./connection";
import { Page } from "../page";
import {
  BoardSettings,
  Address,
  Ingestoid,
  makeTimeConversion,
} from "../common";
import { range } from "../util";
import { getColorIndexFromHue } from "../color";

const useTextInDemo = false;

interface FrameValue {
  count: number;
  x0: number;
  x1: number;
}

interface Series {
  (q0: number, q1: number): FrameValue;
}

interface ChannelSeries {
  ingestoid: Ingestoid;
  colorIndex: number;
  series: Series;
}

const trivialInfoIngestoid = {
  label: ``,
  level: `INFO`,
  color: `5A6763`,
};

const trivialErrorIngestoid = {
  label: ``,
  level: `ERROR`,
  color: `FF0026`,
};

// HTTP requests

function getLevelFromResult(result: number) {
  switch (result) {
    case 2:
      return `INFO`;
    case 3:
      return `INFO`;
    case 4:
      return `WARN`;
    case 5:
      return `ERROR`;
    default:
      return `?`;
  }
}

function makeHttpIngestoid(
  label: string,
  result: number,
  path: string
): Ingestoid {
  return {
    label: `https://${path}`,
    msg_template: `${result}XX`,
    level: getLevelFromResult(result),
    unit: `requests`,
    details: ``,
  };
}

// first, last and number of points between q0 and q1 of a p-spaced grid
function calculateFrame(p: number, q0: number, q1: number): FrameValue {
  const x0 = Math.ceil(q0 / p) * p;
  let x1 = Math.floor(q1 / p) * p;

  if (x1 == q1) x1 -= p;

  const count = (x1 - x0) / p + 1;

  return {
    count,
    x0,
    x1,
  };
}

function makeIntervalSeries(p: number, p0?: number, p1?: number): Series {
  return function (q0: number, q1: number): FrameValue {
    // The explicit truncation feature is disabled for now to make debugging simpler
    // if (p0 && p0 > q0) {
    //   q0 = p0;
    // }
    // if (p1 && p1 < q1) {
    //   q1 = p1;
    // }
    return calculateFrame(p, q0, q1);
  };
}

function makeCombinedSeries(...series: Series[]) {
  return function (q0: number, q1: number): FrameValue {
    let result = { count: 0, x0: Number.MAX_VALUE, x1: 0 };
    for (let s of series) {
      const r = s(q0, q1);
      if (r.count > 0) {
        result.count += r.count;
        result.x0 = Math.min(result.x0, r.x0);
        result.x1 = Math.max(result.x1, r.x1);
      }
    }
    return result;
  };
}

function makeChannelSeries(
  series: Series,
  ingestoid: Ingestoid,
  colorIndex: number
): ChannelSeries {
  return { series, ingestoid, colorIndex };
}

function collectChannelSeriesBatches(...series: ChannelSeries[][]) {
  const result: ChannelSeries[] = [];

  for (let s of series) {
    for (let s2 of s) {
      result.push(s2);
    }
  }

  return result;
}

function makeDemoSeriesFlock() {
  function makeSomeIntervalSeries() {
    // Without rounding to at least ms, some bug manifests leading to weird holes - not sure why
    return makeIntervalSeries(
      Math.round(Math.pow(2, Math.random() * 20 + 1) * 500)
    );
  }

  return range(100).map((i) =>
    makeChannelSeries(
      makeCombinedSeries(makeSomeIntervalSeries()),
      useTextInDemo
        ? makeHttpIngestoid("#" + i, ((i % 5) + 1) * 100, "foo/bar-" + i)
        : trivialInfoIngestoid,
      0
    )
  );
}

export function makeDemoConnection(noOfChannels?: number): Connection {
  let boardSettings: BoardSettings | undefined;

  let seriesFlock = makeDemoSeriesFlock();

  return {
    async fetchTime() {
      return Date.now();
    },

    async fetchInitials() {
      const resolutionLog2 = 0;
      const maxShift = 20;
      const pagesPerShift = 32;

      const upstreamStepMillis = Math.pow(2, resolutionLog2) * 1000;

      const timeSpan =
        Math.pow(2, maxShift) * pagesPerShift * upstreamStepMillis;

      return (boardSettings = {
        name: "Demo inc.",
        pagesPerShift,
        maxShift,
        resolutionLog2,
        pageSize: noOfChannels || 80,
        noOfSystemChannels: 0,
        noOfGenericSystemChannels: 0,
        unixTimeBeginTime: Date.now() - timeSpan,
        time: Date.now(),
        usesAlarms: false,
        viewerSettingsJson: "",
      });
    },

    async fetchAlarmInfo() {
      return undefined;
    },

    redeemBoard() {
      throw Error();
    },

    rearmChannel(channelI: number, arm: boolean) {
      throw Error();
    },

    deleteChannel(channelI: number) {
      throw Error();
    },

    async fetchAddresses() {
      const result = [];

      const s = boardSettings!;

      const timeSpan = s.pagesPerShift * (1 << s.maxShift);

      for (let e = 0; e < s.maxShift; ++e) {
        const offset = (timeSpan >> e) - s.pagesPerShift;

        for (let i = 0; i < s.pagesPerShift; ++i) {
          if (offset + i < 0) throw Error("negative manti");
          result.push({ manti: offset + i, expo: e });
        }
      }

      return result;
    },

    async fetchPage(address: Address) {
      if (!boardSettings) throw Error();

      const pageSize = boardSettings.pageSize;

      const buffer = new ArrayBuffer(Page.BytesPerEntry * pageSize);

      const page = new Page("demo", address, pageSize, buffer);

      const m = address.manti;
      const e = address.expo;

      const timeConversion = makeTimeConversion(boardSettings);

      const isFirst =
        e === boardSettings.maxShift - 1 && m == boardSettings.pagesPerShift;

      for (let i = 0; i < pageSize; ++i) {
        page.setColorIndex(i, seriesFlock[i].colorIndex);
        page.setOrderAndFirst(i, isFirst ? -i - 1 : i + 1);

        const q0 = timeConversion.getMillisFromAddress(address, false);
        const q1 = timeConversion.getMillisFromAddress(address, true);

        const { count, x0, x1 } = seriesFlock[i].series(q0, q1);

        if (count > 0) {
          page.setBegin(i, x0 + boardSettings.unixTimeBeginTime);
          page.setEnd(i, x1 + boardSettings.unixTimeBeginTime);
          page.setCount(i, count);
        }
      }

      return page;
    },

    async fetchIngestoid(
      channelI: number,
      since: number,
      requestRefetch: boolean
    ) {
      return this.fetchBody(channelI);
    },

    async fetchBody(channelI: number) {
      return seriesFlock[channelI].ingestoid;
    },
  };
}
