import { BoardSettings, Ingestoid } from "./common";
import { observable, computed, runInAction } from "mobx";
import { viewerSettings } from "./settings";
import chroma from "chroma-js";

const defaultColor = chroma("#627764");

const laneGap = 1;
const largeGap = 8;
const maxLaneSnap = 5;

const useDebugChannelFocus = false;

export type Channels = ReturnType<typeof createChannels>;
export type Channel = NonNullable<
  ReturnType<Channels["getChannelFromBreadthOffset"]>
>;

// TODO:
// - visible channels -> known channels
// - new channel flag "collapsed"

export function createChannels(settings: BoardSettings) {
  let namedChannelIndexesInOrder = observable.box([] as number[]);

  let now = 0;

  let width = 1;

  let debugChannelIdx: number | undefined = undefined;

  const noOfGenericChannels =
    settings.pageSize -
    settings.noOfSystemChannels +
    settings.noOfGenericSystemChannels;

  const noOfUserChannels =
    noOfGenericChannels - settings.noOfGenericSystemChannels;

  const channels = Array.from(Array(settings.pageSize).keys()).map((i) =>
    observable({
      hasContent: false,
      index: i,
      isSystemCompValue: getIsSystemChannelCompValue(i),
      keyIngestoid: undefined as Ingestoid | undefined,
      bodyIngestoid: undefined as Ingestoid | undefined,
      ingestoidRequestInFlight: false,
      hasStaleKeyIngestoid: true,
      colorIndex: -1,
      color: defaultColor,
      maxValue: undefined as number | undefined,
      minValue: undefined as number | undefined,
      mode: undefined as undefined | "blip" | "value",
      alarmClass: "alarmed-none" as
        | "alarmed-none"
        | "alarmed-redeemed"
        | "alarmed-rearmed"
        | "alarmed-newly",
      weight: 0,
      latestTime: 0,
      resetTime: 0,
      renameTime: 0,
      order: 0,
      layoutOrder: 0,
      x0: 0,
      x1: 0,
      screenX0: 0,
      screenX1: 0,
      screenWidth: 0,
    })
  );

  function setDebugChannel(channelI?: number) {
    if (useDebugChannelFocus) {
      debugChannelIdx = channelI;

      layoutChannels();
    }
  }

  function getIsSystemChannelCompValue(channelI: number) {
    return channelI >= settings.pageSize - settings.noOfSystemChannels ? -1 : 1;
  }

  function getChannelFromBreadthOffset(offset: number, breadth: number) {
    var coord = offset / breadth;

    let closest = channels[0];
    let distance = Number.MAX_VALUE;

    for (let i = 0; i < namedChannelIndexesInOrder.get().length; ++i) {
      const channel = channels[namedChannelIndexesInOrder.get()[i]];

      if (!channel.hasContent) continue;
      if (channel.x1 === channel.x0) continue;

      const dl = channel.x0 - coord;
      const dr = coord - channel.x1;
      const dm = Math.max(dl, dr);

      if (dm < distance) {
        distance = dm;
        closest = channel;
      }
    }
    return distance * breadth < maxLaneSnap ? closest : undefined;
  }

  function compareChannels(a: number, b: number) {
    const ca = channels[a];
    const cb = channels[b];
    return ca.isSystemCompValue - cb.isSystemCompValue || ca.order - cb.order;
  }

  function layoutChannels() {
    runInAction(() => {
      if (debugChannelIdx) {
        layoutDebugChannel();
      } else {
        layoutChannelsNormally();
      }
    });
  }

  function collapseChannel(channelI: number) {
    const channel = channels[channelI];
    // just hide it somehow
    channel.screenX0 = channel.screenX1 = channel.x0 = channel.x1 = 0;
  }

  function layoutDebugChannel() {
    for (let channel of channels) {
      if (channel.index === debugChannelIdx) {
        // ...
      } else {
        collapseChannel(channel.index);
      }
    }
  }

  function layoutChannelsNormally() {
    const namedChannelIndexes = channels
      .slice(0, noOfGenericChannels)
      .map((c, i) => (c.hasContent ? i : -1))
      .filter((i) => i >= 0);

    namedChannelIndexes.sort(compareChannels);

    function suggestLaneWidth(max: number) {
      const maxLaneWidth = Math.round(max);

      const laneWidthExact =
        (width - largeGap) / namedChannelIndexes.length - laneGap;

      const laneWidth = Math.min(laneWidthExact, maxLaneWidth);

      return laneWidth;
    }

    const smallLaneWidth = suggestLaneWidth(width / 80);
    const largeLaneWidth = suggestLaneWidth(width / 10);

    function getLaneWidth(channel: Channel) {
      if (channel.mode === "blip") {
        return smallLaneWidth;
      } else if (channel.mode === "value") {
        return largeLaneWidth;
      } else {
        return smallLaneWidth;
      }
    }

    let hadNonSystemChannel = false;

    let cx = 0;
    for (let i = 0; i < namedChannelIndexes.length; ++i) {
      const channel = channels[namedChannelIndexes[i]];

      if (
        channel.index >= noOfUserChannels &&
        viewerSettings.hideGenericSystemChannels
      ) {
        collapseChannel(channel.index);
        continue;
      }

      if (!hadNonSystemChannel && channel.isSystemCompValue == 1) {
        hadNonSystemChannel = true;
        cx += largeGap;
      } else if (i > 0) {
        cx += laneGap;
      }

      let dx = getLaneWidth(channel);

      channel.layoutOrder = i;
      channel.screenX0 = cx;
      channel.screenX1 = cx + dx;
      channel.screenWidth = channel.screenX1 - channel.screenX0;
      channel.x0 = channel.screenX0 / width;
      channel.x1 = channel.screenX1 / width;

      cx += dx;
    }

    namedChannelIndexesInOrder.set(namedChannelIndexes);
  }

  return {
    noOfGenericChannels,
    noOfUserChannels,
    setNow: (value: number) => {
      now = value;
      layoutChannels();
    },
    setWidth: (value: number) => {
      width = value;
      layoutChannels();
    },
    getHaveLabels: () =>
      computed(
        () =>
          channels
            .map((c) => !!c.keyIngestoid?.label)
            .reduce((l, r) => l || r, false) || false
      ),
    getAllChannels: () => channels,
    getVisibleChannels: () =>
      namedChannelIndexesInOrder.get().map((i) => channels[i]),
    getChannel: (i: number) => channels[i],
    getChannelFromBreadthOffset,
    setDebugChannel,
    layoutChannels,
  };
}
