import { observable } from "mobx";
import { ViewerOrientation } from "./common";

interface ViewerSettings {
  orientation: ViewerOrientation;
  chromacity: number;
  labelChromacityFactor: number;
  subtleColorMarkerCycling: "random" | "layout-cycle";
  hideGenericSystemChannels: boolean;
  defaultTimeRangeInDays: number;
  interactive: boolean;
  inquireGeolocationPosition: boolean;

  tbgEnabled: boolean;
  tbgMarkWeekends: boolean;
  tbgDimming: number;
  tbgFuzzify: boolean;
  tbgPatternAlpha: number;
  tbgDayNightDistinction: number;
  tbgWeekendEmphazisOnFuzzyDays: number;

  fallbackLatitude: number;
  geoLocationSource: string;
}

const defaultSettings: ViewerSettings = {
  orientation: "t2b",
  chromacity: 15,
  labelChromacityFactor: 1,
  subtleColorMarkerCycling: "random",
  hideGenericSystemChannels: false,
  defaultTimeRangeInDays: 30,
  interactive: true,
  inquireGeolocationPosition: false,

  tbgEnabled: true,
  tbgMarkWeekends: true,
  tbgDimming: 5,
  tbgFuzzify: true,
  tbgPatternAlpha: 0.05,
  tbgDayNightDistinction: 0.4,
  tbgWeekendEmphazisOnFuzzyDays: 2,

  fallbackLatitude: 50,
  geoLocationSource: "browser",
};

const viewerSettingsSources = observable.object({
  defaults: defaultSettings,
  boardDefaults: {},
  params: getParamsSettings(),
  local: {},
}) as any as { [name: string]: ViewerSettings };

function getParamsSettings() {
  const params = new URLSearchParams(window.location.search);

  const settings: any = {};

  for (let [key, value] of params.entries()) {
    console.info(`key ${key}`);
    if (key.startsWith("$")) {
      console.info(`key ${key.slice(1)} value ${value} (${typeof value})`);

      const k = key.slice(1);
      const dv = (defaultSettings as any)[k];

      if (typeof dv !== "undefined") {
        settings[key.slice(1)] = convert(k, value, typeof dv);
      }
    }
  }

  return settings as ViewerSettings;
}

function convert(key: string, value: string, type: string): any {
  switch (type) {
    case "string":
      return type;
    default:
      try {
        const parsed = JSON.parse(value);
        if (typeof parsed !== type) {
          console.warn(
            `value '${value}' for param '${key}' parses as '${typeof parsed}', but '${type}' was expected`
          );

          return undefined;
        }
        return parsed;
      } catch (ex) {
        console.warn(
          `value '${value}' for param '${key}' could not be parsed, a type of '${type}' was expected`
        );

        return undefined;
      }
  }
}

export function setBoardViewerSettings(json: string) {
  viewerSettingsSources.boardDefaults = json !== "" ? JSON.parse(json) : {};
}

export function getSetting(name: keyof ViewerSettings) {
  return (
    viewerSettingsSources.local[name] ??
    viewerSettingsSources.params[name] ??
    viewerSettingsSources.boardDefaults[name] ??
    viewerSettingsSources.defaults[name]
  );
}

export const viewerSettings: ViewerSettings = new Proxy(
  {},
  { get: (t, p, r) => getSetting(p as keyof ViewerSettings) }
) as ViewerSettings;

export const localSettings: ViewerSettings = new Proxy(
  viewerSettingsSources.local,
  {}
);
