import { observable, runInAction } from "mobx";
import React from "react";

export function getErrorOrUndefined(value: unknown) {
  return value instanceof Error ? value : undefined;
}

export function formatNumber(number: number) {
  return new Intl.NumberFormat("en", { useGrouping: true }).format(number);
}

const timeUnits = [
  ["second", 1],
  ["minute", 60],
  ["hour", 3600],
  ["day", 3600 * 24],
];

export function getFittingTimeUnit(
  reversed: boolean,
  convert: (s: number) => number
) {
  const units = timeUnits.slice(0);
  if (reversed) {
    units.reverse();
  }

  for (let i = 0; i < units.length; ++i) {
    const unit = units[i][0] as string;
    const s = units[i][1] as number;

    const value = convert(s);

    if (value === 0) return { value, unit: "" };

    if (i === units.length - 1 || value > 1) return { value, unit };
  }

  throw Error();
}

export function collect<T>(add: (f: (t: T) => any) => any) {
  const result: T[] = [];
  add((x) => result.push(x));
  return result;
}

export function range(count: number) {
  const a = [];
  for (let i = 0; i < count; ++i) {
    a.push(i);
  }
  return a;
}

export function clamp(t: number, t0: number, t1: number) {
  if (t < t0) return t0;
  if (t > t1) return t1;
  return t;
}

export const useAnimationFrame = (
  callback: (animationFrame: number) => any
) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = React.useRef(0);
  const previousTimeRef = React.useRef(0);

  const animate = (time: number) => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []); // Make sure the effect runs only once
};

export function scaleToUnit(t: number, t0: number, t1: number) {
  return (t - t0) / (t1 - t0);
}

export function createProxyWithUpdater<T>(target: T) {
  const dummy = observable({
    counter: 0,
  });

  const handler = {
    get(t: any, prop: keyof T, receiver: any) {
      const ignore = dummy.counter;
      return target[prop];
    },
  };

  const update = () => runInAction(() => ++dummy.counter);

  return [new Proxy(target as any, handler as any), update];
}

export function createProxyFromAccessor<T>(
  defaults: T,
  accessor?: () => Partial<T>
) {
  if (!accessor) return defaults;

  const handler = {
    get(target: any, prop: keyof T, receiver: any) {
      const value = accessor()[prop];

      if (typeof value === "undefined") {
        return defaults[prop];
      } else {
        return value;
      }
    },
  };

  return new Proxy(defaults ?? {}, handler as any) as T;
}
