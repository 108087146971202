import chroma from "chroma-js";
import * as twgl from "twgl-base.js";
import {
  GeoLocationInfo,
  isViewerOrientationHorizontal,
  TimeBackgroundRendererSettings,
} from "./common";

import { temporalShader } from "./shader";
import { range } from "../util";
import { ViewerOrientation } from "./common";

const vs = `#version 300 es

uniform mat2 transformMatrix;
uniform vec2 transformOffset;

in vec2 position;

out vec2 v_position;

void main() {
    gl_Position = vec4(position, 0., 1.);

    v_position = transformMatrix * position.xy + transformOffset;
}
`;

const fsTransfer = `#version 300 es
precision highp float;

uniform sampler2D renderBuffer;

in vec2 v_position;

out vec4 o_color;

void main() {
  o_color = texture(renderBuffer, vec2(v_position.x, v_position.y));
}
`;

function createRectangleVertices(
  x0: number,
  y0: number,
  x1: number,
  y1: number
) {
  return {
    numComponents: 2,
    data: [x0, y0, x1, y0, x0, y1, x1, y0, x1, y1, x0, y1],
  };
}

export function createBackgroundScreen(
  canvas: HTMLCanvasElement,
  settings: TimeBackgroundRendererSettings
) {
  const gl2 = canvas.getContext("webgl2");
  const gl = gl2 as WebGLRenderingContext;

  if (!gl2) throw Error("No wegl2!");

  const mainPass = twgl.createProgramInfo(
    gl,
    [vs, temporalShader],
    undefined,
    undefined,
    (e) => {
      throw e;
    }
  );

  const transferPass = twgl.createProgramInfo(
    gl,
    [vs, fsTransfer],
    undefined,
    undefined,
    (e) => {
      throw e;
    }
  );

  const dimming = settings.tbgDimming / 10;

  const daylightBaseColor = chroma.gl(0, dimming * 0.4 * 1.2, 0, 1);

  const nightlightBaseColor = chroma.gl(0, 0, dimming * 0.8, 1);

  function getDayColor(t: number, distinction?: number) {
    if (typeof distinction === "undefined") {
      distinction = settings.tbgDayNightDistinction;
    }
    const t0 = (t - 0.5) * distinction + 0.5;
    return chroma.mix(
      nightlightBaseColor,
      daylightBaseColor,
      t0,
      "lrgb" as any
    );
  }

  function createBackgroundPatternCssExpression() {
    const s0 = 4;

    const b = getDayColor(0.5, settings.tbgDayNightDistinction);

    const c0 = b.brighten(2);
    const c1 = b.darken(2);

    return `repeating-linear-gradient(45deg, ${c0}, ${c0} ${s0}px, ${c1} ${s0}px, ${c1} ${
      s0 * 2
    }px)`;
  }

  function createDawnGradient(n: number) {
    return range(n)
      .map((i) => getDayColor(i / n).gl())
      .flat()
      .map((c) => c * 255);
  }

  const bufferRes = 2000;

  const textures = twgl.createTextures(gl, {
    dawnGradient: {
      mag: gl.NEAREST,
      min: gl.LINEAR,
      wrap: gl.CLAMP_TO_EDGE,
      src: createDawnGradient(256),
      width: 1,
    },
    renderBuffer: {
      mag: gl.NEAREST,
      min: gl.NEAREST,
      wrap: gl.CLAMP_TO_EDGE,
      type: gl2.UNSIGNED_BYTE,
      format: gl.RGBA,
      internalFormat: gl.RGBA,
      src: undefined,
      height: 1,
      width: bufferRes,
    },
  });

  const fb = gl.createFramebuffer();
  gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
  gl.framebufferTexture2D(
    gl2.FRAMEBUFFER,
    gl.COLOR_ATTACHMENT0,
    gl.TEXTURE_2D,
    textures.renderBuffer,
    0
  );

  const bufferInfo = twgl.createBufferInfoFromArrays(gl, {
    position: createRectangleVertices(-1, -1, 1, 1) as any,
  });

  twgl.setBuffersAndAttributes(gl, mainPass, bufferInfo);
  twgl.setBuffersAndAttributes(gl, transferPass, bufferInfo);

  function renderCore() {
    twgl.drawBufferInfo(gl, bufferInfo);
  }

  function render(
    now: number,
    t0: number,
    t1: number,
    orientation: ViewerOrientation,
    linearity: number,
    geoLocationInfo: GeoLocationInfo
  ) {
    twgl.resizeCanvasToDisplaySize(canvas);

    if (settings.tbgMarkWeekends && !canvas.style["background"]) {
      canvas.style["background"] = createBackgroundPatternCssExpression();
    }

    const horizontal = isViewerOrientationHorizontal(orientation);

    const canvasRes = horizontal ? canvas.width : canvas.height;

    gl.useProgram(mainPass.program);
    twgl.setUniforms(mainPass, {
      lat: geoLocationInfo.latitude,
      lng: geoLocationInfo.longitude,
      zoneOffsetMillis: geoLocationInfo.tzoffset * 60 * 1000,
      linearity,
      now,
      t0,
      t1,
      transformMatrix: [1, 0, 0, 1],
      transformOffset: [0, 0],
      dawnGradient: textures.dawnGradient,
      halfPixelSize: 0.5 / canvasRes,
      tbgWeekendEmphazisOnFuzzyDays: settings.tbgWeekendEmphazisOnFuzzyDays,
      tbgPatternAlpha: settings.tbgPatternAlpha,
      featureWeekends: settings.tbgMarkWeekends,
      featureDaylight: settings.tbgFuzzify,
    });
    gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
    gl.viewport(0, 0, bufferRes, 1);
    twgl.drawBufferInfo(gl, bufferInfo);

    gl.useProgram(transferPass.program);
    twgl.setUniforms(transferPass, {
      transformMatrix: horizontal ? [-0.5, 0, 0, 0] : [0, 0, -0.5, 0],
      transformOffset: [0.5, 0],
      renderBuffer: textures.renderBuffer,
    });
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.viewport(0, 0, canvas.width, canvas.height);
    renderCore();
  }

  function dispose() {}

  return { render, dispose };
}

export type BackgroundScreen = ReturnType<typeof createBackgroundScreen>;
