import { bind } from "mousetrap";
import { observable } from "mobx";
import { localSettings, viewerSettings } from "./settings";
import { inquireAndUpdateGeolocationPosition } from "./geolocation";

export let debugShift = 0;
export let debugColoration = 0;
export let debugIgnoreChannelReset = false;
export let debugMargin = 2;
export let debugPendingChannelInfoFetch = false;
export let debugLabels = observable.box(0);

export function resetChannelInfoFetch() {
  debugPendingChannelInfoFetch = false;
}

export interface HotCommand {
  setHandler(h?: () => any): any;
}

export const debugSettings = observable({
  timelineDebugInfo: false,
});

function createHotCommand(
  keys: string,
  handler?: (() => any) | undefined
): HotCommand {
  function setHandler(h?: () => any) {
    handler = h;
  }

  bind(keys, () => handler && handler());

  return { setHandler };
}

bind("d s", () => {
  debugShift = (debugShift + 1) % 2;
});

bind("d c", () => {
  debugColoration = (debugColoration + 1) % 2;
});

bind("d i", () => {
  debugIgnoreChannelReset = !debugIgnoreChannelReset;
});

bind("d m", () => {
  debugMargin = -debugMargin;
});

bind("d f", () => {
  debugPendingChannelInfoFetch = true;
});

bind("d l", () => {
  debugLabels.set((debugLabels.get() + 1) % 3);
});

export const toggleChannelArmingCommand = createHotCommand("a");

export const redeemAlarmsCommand = createHotCommand("r");

export const deleteChannelCommand = createHotCommand("c d");

const changeOrientation = createHotCommand("d o");
changeOrientation.setHandler(() => {
  localSettings.orientation =
    viewerSettings.orientation === "t2b" ? "r2l" : "t2b";
});

const changeTimelineDebugInfo = createHotCommand("d t");
changeTimelineDebugInfo.setHandler(
  () => (debugSettings.timelineDebugInfo = !debugSettings.timelineDebugInfo)
);

createHotCommand("d g e o", inquireAndUpdateGeolocationPosition);
createHotCommand(
  "d b t",
  () => (localSettings.tbgEnabled = !viewerSettings.tbgEnabled)
);
