export interface GeoLocationCoords {
  latitude: number;
  longitude: number;
}

export type GeoLocationInfo = GeoLocationCoords & {
  tzoffset: number;
  isUnreliable?: boolean;
};

export type ViewerOrientation = "t2b" | "r2l";

export function isViewerOrientationHorizontal(orientation: ViewerOrientation) {
  switch (orientation) {
    case "t2b":
      return false;
    case "r2l":
      return true;
    default:
      throw Error(`Unkown orientation ${orientation}`);
  }
}

export interface TimeBackgroundRendererSettings {
  tbgEnabled: boolean;
  tbgMarkWeekends: boolean;
  tbgDimming: number;
  tbgFuzzify: boolean;
  tbgPatternAlpha: number;
  tbgDayNightDistinction: number;
  tbgWeekendEmphazisOnFuzzyDays: number;
}

export const timeBackgroundRendererDefaultSettings: TimeBackgroundRendererSettings =
  {
    tbgEnabled: true,
    tbgMarkWeekends: true,
    tbgDimming: 5,
    tbgFuzzify: true,
    tbgPatternAlpha: 0.05,
    tbgDayNightDistinction: 0.4,
    tbgWeekendEmphazisOnFuzzyDays: 2,
  };
