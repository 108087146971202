import chroma from "chroma-js";
import { viewerSettings } from "./settings";

function clampByte(b: number) {
  b = Math.round(b);
  return b > 255 ? 255 : b < 0 ? 0 : b;
}

export function getInt32From4Bytes(
  b0: number,
  b1: number,
  b2: number,
  b3?: number
) {
  return (
    (clampByte(b3 ?? 0) << 24) +
    (clampByte(b2) << 16) +
    (clampByte(b1) << 8) +
    clampByte(b0)
  );
}

export function encodeRgbAsInt24(r: number, g: number, b: number) {
  return getInt32From4Bytes(b, g, r);
}

const levelColors = ["#9D8495", "#2A9A91", "#63993A", "#CC760C", "#F45244"];

function getLeveLFromIndex(i: number) {
  switch (i) {
    case 4:
      return "TRACE";
    case 8:
      return "DEBUG";
    case 12:
      return "INFO";
    case 16:
      return "WARN";
    case 20:
      return "ERROR";
    default:
      return undefined;
  }
}

function mulberry32(a: number) {
  return function () {
    var t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

function createRandomAngles(n: number) {
  const r = new Array(n);

  let v = 0;

  for (let i = 0; i < n; ++i) {
    const delta = 115;
    v += delta;
    r[i] = v;
  }

  return r;
}

const randomAngles = createRandomAngles(180);

function getSubtleColorAngle(order: number, id: number) {
  switch (viewerSettings.subtleColorMarkerCycling) {
    case "random":
      return mulberry32(id)() * 360;
    case "layout-cycle":
      return (115 * order) % 360;
  }
}

function getColorFromLevel(
  level: string | undefined,
  order: number,
  id: number
) {
  const a = getSubtleColorAngle(order, id);
  switch (level) {
    case "TRACE":
      return chroma.lch(30, 5, a);
    case "DEBUG":
      return chroma.lch(45, 5, a);
    case "INFO":
    default:
      return chroma.lch(60, viewerSettings.chromacity, a);
    case "WARN":
      return chroma.lch(60, 50, 70);
    case "ERROR":
      return chroma.lch(60, 70, 35);
  }
}

export function getLabelColorForChannelColor(color: chroma.Color) {
  const [l, c, h] = color.lch();

  return chroma.lch(
    25,
    viewerSettings.chromacity * viewerSettings.labelChromacityFactor,
    h
  );
}

export function getColorIndexFromHue(a: number) {
  if (a < 0) throw Error();
  return 512 + Math.floor(a % 360);
}

export function getColorFromIndex(i: number, order: number, id: number) {
  const level = getLeveLFromIndex(i);
  const color = getColorFromLevel(level, order, id);
  return color;
}
